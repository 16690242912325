/*
@File: StartP Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader CSS
** - Navbar CSS
** - Main Banner CSS
** - Boxes CSS
** - About CSS
** - Why Choose Us CSS
** - Services CSS
** - Services Details CSS
** - Features CSS
** - Features Details CSS
** - Team CSS
** - Works CSS
** - Fun Facts CSS
** - Pricing CSS
** - Domain Search CSS
** - Feedback CSS
** - Ready To Talk CSS
** - Partner CSS
** - Blog CSS
** - Shop CSS
** - Shop Details CSS
** - Cart CSS
** - Checkout CSS
** - Page Title CSS
** - FAQ CSS
** - Pagination CSS
** - Blog Details CSS
** - Sidebar Widget CSS
** - Project Details CSS
** - Contact CSS
** - Coming Soon CSS
** - 404 Error CSS
** - CTA CSS
** - Repair Main Banner CSS
** - Repair Partner CSS
** - Repair About CSS
** - Repair Services CSS
** - Repair CTA CSS
** - Repair Team CSS
** - Repair Why Choose Us CSS
** - Repair Feedback CSS
** - IoT Main Banner CSS
** - IoT Partner CSS
** - Iot Services CSS
** - IoT CTA CSS
** - IoT Features CSS
** - IoT Team CSS
** - IoT Why Choose Us CSS
** - ML Main Banner CSS
** - ML Partner CSS
** - Our Solutions CSS
** - ML About CSS
** - ML Services CSS
** - ML Feedback CSS
** - Free Trial CSS
** - ML Projects CSS
** - Agency Main Banner CSS
** - Our Solutions CSS
** - Agency About CSS
** - Agency Services CSS
** - Testimonials CSS
** - Agency CTA CSS
** - Agency Portfolio Main Banner CSS
** - Our Latest Projects CSS
** - Agency Portfolio About CSS
** - Agency Portfolio Partner CSS
** - Creative Area CSS
** - Footer CSS
** - Auth Form CSS
** - Go Top CSS
*/

/*================================================
Default CSS
=================================================*/
$light-green-color: #44ce6f;
$white-color: #ffffff;
$black-color: #0e314c;
$navlink-color: #4a6f8a;
$paragraph-color: #6084a4;
$purple-color: #c679e3;
$bg-color: #f7fafd;
$bg-color2: #f9f6f6;
$hosting-main-color: #032b8d;
$gradient-color: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
$gradient-color2: linear-gradient(
  to right bottom,
  #1fa2ff,
  #00c0ff,
  #00d9f2,
  #53eedd,
  #a6ffcb
);
$transition: 0.5s;
$font-size: 14px;

body {
  padding: 0;
  margin: 0;
  font-size: $font-size;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  color: $black-color;
  display: inline-block;
  transition: $transition;
  text-decoration: none;
  outline: 0 !important;

  &:hover,
  &:focus {
    color: $light-green-color;
    text-decoration: none;
  }
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
p {
  color: $paragraph-color;
  line-height: 1.8;

  &:last-child {
    margin-bottom: 0;
  }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black-color;
  font-weight: 500;
}
.text-right {
  text-align: end;
}
:focus {
  outline: 0 !important;
}
.ptb-80 {
  padding: {
    top: 80px;
    bottom: 80px;
  }
}
.pt-80 {
  padding-top: 80px;
}
.pb-50 {
  padding-bottom: 50px;
}
.bg-f7fafd {
  background-color: $bg-color;
}
.bg-f9f6f6 {
  background-color: $bg-color2;
}
.bg-f9fafb {
  background-color: #f9fafb;
}
.bg-0f054b {
  background-color: #0f054b;
}
.bg-eef6fd {
  background-color: #eef6fd;
}
.pb-80 {
  padding-bottom: 80px;
}
textarea.form-control {
  height: auto !important;
}

// swiper-pagination
.swiper-pagination {
  position: initial;
  .swiper-pagination-bullet {
    background-color: #d6d6d6;
    opacity: 1;
    width: 12px;
    height: 12px;
    &.swiper-pagination-bullet-active,
    &:hover {
      background-color: $light-green-color;
    }
  }
}

/* btn btn-primary */
.btn-primary {
  color: $white-color;
  background-color: $light-green-color;
  box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);

  &:hover,
  &:focus,
  &:active {
    color: $white-color;
    background: $black-color;
    transform: translateY(-5px);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
  }
  &.focus,
  &:focus {
    box-shadow: unset;
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: unset;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: $white-color;
    background: $black-color;
    transform: translateY(-5px);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
  }
  &.disabled,
  &:disabled {
    color: $white-color;
    background-color: $light-green-color;
    box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    opacity: 1;

    &:hover,
    &:focus {
      background: $black-color;
      box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    }
  }
}
.btn {
  font: {
    weight: 600;
    size: 14px;
  }
  border: none;
  padding: 15px 40px;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  text-transform: uppercase;
  transition: $transition;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 0;
    transition: $transition;
    background: $purple-color;
    z-index: -1;
  }
  &::after {
    left: 0;
    border-radius: 4px 0 0 4px;
  }
  &::before {
    right: 0;
    border-radius: 0 4px 4px 0;
  }
  &:hover {
    &::after,
    &::before {
      width: 50%;
    }
  }
}

/* btn-secondary */
.btn {
  &.btn-secondary {
    text-transform: capitalize;
    border-radius: 0;
    background-color: $light-green-color;
    padding: 13px 30px;
    box-shadow: 5px 5px 5px #a6f5be;
    margin: {
      right: 5px;
      bottom: 5px;
    }
    font: {
      size: 14px;
      weight: 500;
    }
    &::before {
      border-radius: 0;
      background: $purple-color;
    }
    &::after {
      border-radius: 0;
      background: $purple-color;
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      color: $white-color;
      background-color: $light-green-color;
      border-color: $light-green-color;
    }
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 5px 5px 5px #e9caf5;
    }
    &:focus {
      color: $white-color;
    }
    &:hover,
    &:focus {
      box-shadow: 5px 5px 5px #e9caf5;
    }
  }
}

/* Form control */
.form-group {
  margin-bottom: 18px;
}
label {
  color: $black-color;
  font: {
    size: 15px;
    weight: 500;
  }
  margin-bottom: 10px;
}
.form-control {
  height: 45px;
  padding: 10px 15px;
  font-size: 14px;
  color: $black-color;
  border: 1px solid #eeeeee;
  border-radius: 0;
  background: #eeeeee;
  transition: $transition;

  &:focus {
    outline: 0;
    background: $white-color;
    box-shadow: unset;
    border-color: $light-green-color;
  }
}
/* Section Title */
.section-title {
  text-align: center;
  margin-bottom: 50px;

  h2 {
    margin-bottom: 0;
    text-transform: capitalize;
    font: {
      size: 30px;
      weight: 600;
    }
  }
  p {
    max-width: 520px;
    margin: 0 auto;
  }
  .bar {
    height: 5px;
    width: 90px;
    background: #cdf1d8;
    margin: 20px auto;
    position: relative;
    border-radius: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -2.7px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: $light-green-color;
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-name: MOVE-BG;
    }
  }
  .sub-title {
    display: inline-block;
    color: $purple-color;
    background-color: #f5dffd;
    margin-bottom: 18px;
    border-radius: 30px;
    padding: 5px 20px 4px;
    font-size: 13px;
  }
  &.text-left {
    text-align: left;
    h2 {
      font-weight: 500;

      span {
        display: inline-block;
        font-weight: 700;
      }
    }
    p {
      margin: {
        left: 0;
        right: 0;
        top: 10px;
      }
    }
  }
}
@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(88px);
  }
}

/*================================================ 
Preloader CSS
=================================================*/
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: $light-green-color;

  .spinner {
    width: 60px;
    height: 60px;
    background-color: $white-color;
    margin: 0 auto;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    position: absolute;
    right: 0;
    left: 0;
    box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.4);
    top: 50%;
    margin-top: -75px;
  }
}
@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/*================================================
Navbar CSS
=================================================*/
#header {
  &.headroom {
    position: fixed;
    width: 100%;
    z-index: 9998;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: 0.3s;
    padding-top: 15px;

    &.is-sticky {
      box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
      background-color: $white-color !important;
      padding: {
        top: 15px;
        bottom: 15px;
      }
    }
    &.navbar-style-two {
      background-color: $white-color;
      padding: {
        top: 15px;
        bottom: 15px;
      }
    }
  }
  &.headroom--pinned {
    transform: translateY(0%);
  }
  &.headroom--unpinned {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
  }
}
.startp-nav {
  background: transparent;

  nav {
    padding: 0;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
    .navbar-nav {
      .nav-item {
        position: relative;
        padding: 15px 0;

        a {
          font: {
            weight: 500;
            size: 15px;
          }
          color: $navlink-color;
          padding: {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
          margin: {
            left: 14px;
            right: 14px;
          }
          &:hover,
          &:focus,
          &.active {
            color: $light-green-color;
          }
          svg {
            width: 14px;
          }
        }
        .dropdown-menu {
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
          background: $white-color;
          position: absolute;
          border: none;
          top: 80px;
          left: 0;
          width: 250px;
          display: block;
          z-index: 99;
          padding: {
            top: 15px;
            left: 5px;
            right: 5px;
            bottom: 15px;
          }
          opacity: 0;
          border-radius: 5px;
          visibility: hidden;
          transition: all 0.3s ease-in-out;

          li {
            position: relative;
            padding: 0;

            a {
              font: {
                size: 14px;
                weight: 500;
              }
              padding: 8px 15px;
              margin: 0;
              display: block;
              color: $navlink-color;

              &:hover,
              &:focus,
              &.active {
                color: $light-green-color;
              }

              svg,
              i {
                float: right;
              }
            }
            .dropdown-menu {
              position: absolute;
              left: 100%;
              top: 0;
              opacity: 0 !important;
              visibility: hidden !important;

              li {
                a {
                  color: $navlink-color;

                  &:hover,
                  &:focus,
                  &.active {
                    color: $light-green-color;
                  }
                }
              }
            }
            &:hover {
              .dropdown-menu {
                opacity: 1 !important;
                visibility: visible !important;
                top: -10px !important;
              }
              a {
                color: $light-green-color;
              }
            }
          }
        }
        &:hover {
          ul {
            opacity: 1;
            visibility: visible;
            top: 100%;
          }
        }
      }
    }
    .others-option {
      margin-left: 20px;

      .cart-wrapper-btn {
        position: relative;
        margin-right: 30px;

        svg {
          width: 15px;
        }
        i {
          font-size: 20px;
        }
        span {
          background-color: red;
          color: #ffffff;
          font-size: 9px;
          width: 13px;
          height: 13px;
          text-align: center;
          line-height: 14px;
          border-radius: 50%;
          position: absolute;
          top: -2px;
          right: -9px;
        }
      }
      .btn {
        &.btn-light {
          background: transparent;
          border: 2px dashed #cdf1d8;
          padding: 12px 33px;
          margin: {
            right: 10px;
          }
          &:focus {
            color: $black-color !important;
          }
          &:hover,
          &:focus {
            color: $white-color;
            border-color: $light-green-color;
          }
          &::after,
          &::before {
            background: $light-green-color;
          }
        }
        &.btn-primary {
          background: $purple-color;
          box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

          &::after,
          &::before {
            background: $light-green-color;
            box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
          }
        }
      }
    }
  }
}
.navbar-style-three {
  .startp-nav {
    padding: {
      left: 25px;
      right: 25px;
    }
  }
}
.navbar-color-white {
  .startp-nav {
    nav {
      .navbar-nav {
        .nav-item {
          a {
            color: $white-color;

            &:hover,
            &:focus,
            &.active {
              color: $light-green-color;
            }
          }
        }
      }
      .others-option {
        .cart-wrapper-btn {
          color: $white-color;
        }
        .btn {
          &.btn-primary {
            background: $purple-color;
            box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

            &::after,
            &::before {
              background: $light-green-color;
              box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
            }
          }
        }
      }
    }
  }
  &#header.headroom.is-sticky {
    background-color: $black-color !important;
  }
}

/* Navbar toggler */
.navbar-toggler {
  border: none;
  border-radius: 0;
  padding: 0;

  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }

  .icon-bar {
    width: 35px;
    transition: all 0.3s;
    background: #221638;
    height: 4px;
    display: block;
    border-radius: 3px;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
    left: 4px;
    position: relative;
  }
  .middle-bar {
    opacity: 0;
    margin: 5px 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
    left: 4px;
    position: relative;
  }
  &.collapsed {
    .top-bar {
      transform: rotate(0);
      left: 0;
    }
    .middle-bar {
      opacity: 1;
    }
    .bottom-bar {
      transform: rotate(0);
      left: 0;
    }
  }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
  #header {
    padding: {
      top: 15px;
      bottom: 15px;
    }
    &.headroom {
      border-bottom: 1px solid #eeeeee;

      &.is-sticky {
        border-bottom: none;
      }
    }
  }
}

/*================================================
Main Banner CSS
=================================================*/
.main-banner {
  position: relative;
  height: 910px;
  z-index: 1;
  background: transparent url(/default_images/banner-bg1.jpg) right top
    no-repeat;

  .hero-content {
    h1 {
      font: {
        size: 45px;
        weight: 500;
      }
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 30px;
    }
  }
  .banner-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 30px;
    border-radius: 5px;

    form {
      .form-control {
        padding-left: 15px;
      }
      .btn {
        display: block;
        width: 100%;
        transform: unset;
        margin-top: 15px;
      }
      .form-check {
        margin-bottom: 0;
        label {
          margin: 0;
          font-size: 13px;
          font-weight: 400;
          a {
            color: $light-green-color;
          }
        }
      }
    }
  }
  .banner-image {
    position: relative;

    img {
      position: absolute;

      &:nth-child(1) {
        z-index: 3;
        top: -130px;
        left: -45px;
      }
      &:nth-child(2) {
        right: 145px;
        top: -287px;
      }
      &:nth-child(3) {
        left: 25px;
        top: -20px;
      }
      &:nth-child(4) {
        right: 12%;
        top: 118px;
        z-index: 1;
      }
      &:nth-child(5) {
        left: 28%;
        top: -120px;
        z-index: 2;
      }
      &:nth-child(6) {
        right: 35%;
        z-index: 1;
        top: -150px;
      }
      &:nth-child(7) {
        right: 20px;
        top: -110px;
      }
      &:nth-child(8) {
        right: 25px;
        top: -140px;
      }
      &:nth-child(9) {
        right: 30%;
        z-index: 1;
        top: -35px;
      }
      &:nth-child(10) {
        left: 37%;
        top: -28px;
        z-index: 1;
      }
      &:nth-child(11) {
        left: 37%;
        top: -160px;
        z-index: 1;
      }
      &:nth-child(12) {
        left: 90px;
        top: -110px;
      }
      &:nth-child(13) {
        left: 56%;
        top: -25px;
        z-index: 2;
      }
      &:nth-child(14) {
        right: 105px;
        top: -28px;
      }
      &:last-child {
        display: none;
      }
    }
    &.single-banner-image {
      img {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
.hosting-main-banner {
  position: relative;
  z-index: 1;
  background-color: $hosting-main-color;
  padding: {
    top: 250px;
    bottom: 190px;
  }
  .hosting-banner-content {
    h1 {
      font: {
        size: 45px;
        weight: 500;
      }
      color: $white-color;
      margin-bottom: 25px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 12px;
        color: $white-color;
        position: relative;
        padding-left: 30px;

        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 2px;
          background: $light-green-color;
          content: "";
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    p {
      color: $white-color;
      font-size: 18px;
      margin: {
        bottom: 30px;
        top: 25px;
      }
      span {
        text-decoration: line-through;
      }
    }
  }
  .hosting-banner-image {
    position: relative;

    img {
      position: absolute;

      &:nth-child(1) {
        top: -175px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &:nth-child(2) {
        top: 184px;
        left: 21%;
      }
      &:nth-child(3) {
        top: 177px;
        right: 38px;
      }
      &:nth-child(4) {
        left: 29%;
        right: 0;
        top: -15px;
        margin: 0 auto;
      }
      &:nth-child(5) {
        right: 8px;
        top: -107px;
      }
      &:nth-child(6) {
        left: 18%;
        top: -175px;
        z-index: -1;
      }
      &:nth-child(7) {
        top: -235px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &:nth-child(8) {
        left: 27%;
        top: -200px;
      }
      &:nth-child(9) {
        z-index: -2;
        top: 40px;
        left: 80px;
        right: 0;
        margin: 0 auto;
      }
      &:nth-child(10) {
        display: none;
      }
    }
    &.single-hosting-banner-image {
      img {
        display: block;
        position: relative;
        top: 0 !important;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
.shape1 {
  position: absolute;
  top: 20%;
  z-index: -1;
  left: 30px;
  animation: rotate3d 4s linear infinite;
  opacity: 0.4;
}
.shape2 {
  position: absolute;
  z-index: -1;
  top: 60%;
  left: 15%;
}
.shape3 {
  position: absolute;
  left: 25%;
  bottom: 15%;
  z-index: -1;
  animation: animationFramesOne 15s infinite linear;
}
.shape4 {
  position: absolute;
  right: 25%;
  bottom: 15%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.shape5 {
  position: absolute;
  right: 5%;
  top: 10%;
  z-index: -1;
  animation: movebounce 5s linear infinite;
  opacity: 0.2;
}
.shape6 {
  position: absolute;
  z-index: -1;
  top: 40%;
  right: 10%;
}
.shape7 {
  position: absolute;
  left: 25%;
  top: 15%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.shape8 {
  position: absolute;
  z-index: -1;
  top: 15%;
  right: 10%;
}
@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
.rotateme {
  animation-name: rotateme;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

/*================================================
Boxes CSS
=================================================*/
.boxes-area {
  position: relative;
  padding-bottom: 80px;
  margin-top: -130px;
  z-index: 1;
}
.hosting-boxes-area {
  background-color: $hosting-main-color;
  margin-top: 0;

  .single-box {
    padding: {
      left: 15px;
      right: 15px;
    }
  }
}
.single-box {
  border: 1px dashed #cdf1d8;
  padding: 30px 20px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: $white-color;
  box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
  transition: $transition;

  .icon {
    display: inline-block;
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    border-radius: 50%;
    color: $light-green-color;
    transition: $transition;
  }
  h3 {
    font-size: 18px;
    margin: {
      top: 25px;
      bottom: 15px;
    }
    transition: $transition;

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background: $gradient-color;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  p {
    transition: $transition;
  }
  &.bg-f78acb {
    border-color: rgba(247, 138, 203, 0.3);

    .icon {
      color: #f78acb;
      background: rgba(247, 138, 203, 0.3);
    }
  }
  &.bg-c679e3 {
    border-color: #edc3fc;

    .icon {
      color: $purple-color;
      background: #edc3fc;
    }
  }
  &.bg-eb6b3d {
    border-color: rgba(235, 107, 61, 0.3);

    .icon {
      color: #eb6b3d;
      background: rgba(235, 107, 61, 0.3);
    }
  }
  &:hover {
    transform: translateY(-9px);
    border-color: transparent;

    &::before {
      opacity: 1;
      visibility: visible;
    }
    .icon {
      background: $white-color;
      color: $light-green-color;
    }
    h3,
    p,
    a {
      color: $white-color;
    }
    &.bg-f78acb {
      .icon {
        color: #f78acb;
      }
    }
    &.bg-c679e3 {
      .icon {
        color: $purple-color;
      }
    }
    &.bg-eb6b3d {
      .icon {
        color: #eb6b3d;
      }
    }
  }
}

/*================================================
About CSS
=================================================*/
.about-content {
  .section-title {
    text-align: left;
    margin-bottom: 18px;

    .bar {
      margin: {
        right: 0;
        left: 0;
      }
    }
    p {
      margin: 0;
    }
  }
}
.about-inner-area {
  margin-top: 60px;

  .about-text {
    h3 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
}

/*================================================
Why Choose US CSS
=================================================*/
.why-choose-us {
  background: transparent url(/default_images/banner-bg1.jpg) right top
    no-repeat;
  position: relative;
  z-index: 1;

  .section-title {
    text-align: left;

    .bar {
      margin: {
        left: 0;
        right: 0;
      }
    }
    p {
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
}
.why-choose-us-image {
  position: relative;
  text-align: center;

  .animate-cercle-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    max-width: 460px;
  }
}
.col-lg-6 {
  .col-lg-6 {
    &:nth-child(1) {
      .single-why-choose-us {
        margin-top: 60px;
      }
    }
    &:nth-child(2) {
      .single-why-choose-us {
        margin-top: 0;
      }
    }
    &:nth-child(4) {
      .single-why-choose-us {
        margin-top: -30px;
      }
    }
  }
}
.single-why-choose-us {
  padding: 30px 15px;
  border-radius: 5px;
  text-align: center;
  background: $white-color;
  box-shadow: 0 0 30px rgba(56, 46, 46, 0.1);
  transition: $transition;
  margin-top: 30px;

  .icon {
    color: $light-green-color;
    transition: $transition;

    i {
      &::before {
        font-size: 45px;
      }
    }
  }
  h3 {
    font-size: 18px;
    margin: {
      top: 15px;
      bottom: 15px;
    }
    transition: $transition;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background: $gradient-color;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  p {
    transition: $transition;
  }
  &:hover {
    transform: translateY(-9px);
    border-color: transparent;

    &::before {
      opacity: 1;
      visibility: visible;
    }
    .icon {
      color: $white-color;
    }
    h3,
    p {
      color: $white-color;
    }
  }
}

/*================================================
Services CSS
=================================================*/
.services-area {
  overflow: hidden;
}
.services-content {
  .section-title {
    text-align: left;
    margin-bottom: 20px;

    .bar {
      margin: {
        left: 0;
        right: 0;
      }
    }
    p {
      margin: 0;
    }
  }
  .row {
    margin: {
      left: -8px;
      right: -8px;
    }
    .col-lg-6 {
      padding: {
        left: 8px;
        right: 8px;
      }
    }
  }
  .box {
    position: relative;
    z-index: 1;
    display: block;
    padding: 15px 12px 15px 45px;
    color: #6084a4;
    font-weight: 500;
    border-radius: 3px;
    background-color: $white-color;
    transition: $transition;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    margin: {
      top: 20px;
    }
    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: $gradient-color;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      border-radius: 3px;
      z-index: -1;
      transition: $transition;
    }
    svg {
      margin-right: 5px;
      display: inline-block;
      color: $light-green-color;
      transition: $transition;
      width: 18px;
      position: absolute;
      left: 15px;
      top: 12px;
    }
    &:hover {
      color: $white-color;
      transform: translateY(-5px);

      &::before {
        opacity: 1;
        visibility: visible;
      }
      svg {
        color: $white-color;
      }
    }
  }
}
.services-right-image {
  position: relative;

  img {
    position: absolute;

    &:nth-child(1) {
      left: 22%;
      top: -85px;
      z-index: 1;
    }
    &:nth-child(2) {
      left: 0;
      right: 0;
      text-align: center;
      margin: 0 auto;
      top: -52px;
    }
    &:nth-child(3) {
      left: 44%;
      top: -33px;
      z-index: 1;
    }
    &:nth-child(4) {
      right: 38%;
      z-index: 1;
      top: -220px;
    }
    &:nth-child(5) {
      z-index: 1;
      right: 24%;
      top: 8px;
    }
    &:nth-child(6) {
      left: 29%;
      top: 0;
      z-index: 1;
    }
    &:nth-child(7) {
      right: 44%;
      top: -78px;
      z-index: 1;
    }
    &:nth-child(8) {
      right: 30%;
      top: -134px;
      z-index: 1;
    }
    &:nth-child(9) {
      right: 31%;
      top: -21px;
      z-index: 1;
    }
    &:nth-child(10) {
      right: 24%;
      top: -80px;
    }
    &:nth-child(11) {
      left: 27%;
      top: -60px;
      z-index: 2;
    }
    &:nth-child(12) {
      right: 28%;
      z-index: 1;
      top: -54px;
    }
    &:nth-child(13) {
      left: 31%;
      top: -105px;
      z-index: 1;
    }
    &.bg-image {
      top: -262px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
    }
    &:last-child {
      display: none;
    }
  }
  &.single-right-image {
    img {
      display: block;

      &:first-child {
        top: -60px;
      }
      &:last-child {
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }
}
.services-left-image {
  position: relative;

  img {
    position: absolute;

    &:nth-child(1) {
      left: 15%;
      top: -98px;
    }
    &:nth-child(2) {
      left: 0;
      top: -200px;
      right: 0;
      text-align: center;
      margin: 0 auto;
    }
    &:nth-child(3) {
      left: 36%;
      z-index: 12;
      top: -55px;
    }
    &:nth-child(4) {
      left: 55px;
      top: -38px;
      z-index: 1;
    }
    &:nth-child(5) {
      z-index: 3;
      right: 26%;
      top: -66px;
    }
    &:nth-child(6) {
      right: 19%;
      top: -41px;
      z-index: 2;
    }
    &:nth-child(7) {
      right: 97px;
      top: 40px;
      z-index: 1;
    }
    &:nth-child(8) {
      right: 23%;
      top: -175px;
      z-index: 2;
    }
    &.bg-image {
      top: -262px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
    }
    &:last-child {
      display: none;
    }
  }
  &.single-left-image {
    img {
      display: block;

      &:first-child {
        top: -60px;
      }
      &:last-child {
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }
}
.single-services-box {
  position: relative;
  background: $white-color;
  padding: {
    left: 25px;
    top: 30px;
    right: 25px;
    bottom: 30px;
  }
  margin-bottom: 30px;
  border-radius: 5px;
  transition: $transition;

  h3 {
    font-size: 18px;
    margin: {
      top: 25px;
      bottom: 15px;
    }
  }
  .icon {
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    border-radius: 50%;
    color: $light-green-color;
    transition: $transition;

    &.bg-c679e3 {
      color: $purple-color;
      background: #edc3fc;
    }
    &.bg-eb6b3d {
      color: #eb6b3d;
      background: rgba(235, 107, 61, 0.3);
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    background: $light-green-color;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    transition: $transition;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .icon {
      background: $light-green-color;
      color: $white-color;

      &.bg-c679e3 {
        background: $purple-color;
      }
      &.bg-eb6b3d {
        background: #eb6b3d;
      }
    }
    &::after,
    &::before {
      opacity: 1;
      visibility: visible;
      width: 50%;
    }
  }
}

/*=============================
Bigdata Services CSS
===============================*/
.single-services-box-item {
  background-color: $white-color;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: $transition;
  padding: 90px 35px 70px;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);

  .icon {
    width: 90px;
    height: 90px;
    text-align: center;
    position: relative;
    background-color: #80c54d;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 25px;

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
    }
  }
  h3 {
    margin-bottom: 12px;
    font: {
      size: 20px;
      weight: 600;
    }
    a {
      display: inline-block;
    }
  }
  .shape {
    position: absolute;
    right: 0;
    top: -15px;
    z-index: -1;
  }
  .learn-more-btn {
    color: $light-green-color;
    font-weight: 500;
    display: inline-block;
    margin-top: 5px;
    position: relative;
    padding-left: 28px;

    svg {
      position: absolute;
      left: 0;
      top: -2px;
      transition: $transition;
    }
    &:hover {
      color: $black-color;
      padding: {
        left: 0;
        right: 5px;
      }
      svg {
        left: 100%;
        color: $light-green-color;
      }
    }
  }
  &:hover {
    transform: translateY(-10px);
  }
}
.col-lg-4 {
  &:nth-child(2) {
    .single-services-box-item {
      .icon {
        background-color: #4267d1;
      }
    }
  }
  &:nth-child(3) {
    .single-services-box-item {
      .icon {
        background-color: #ca8220;
      }
    }
  }
  &:nth-child(4) {
    .single-services-box-item {
      .icon {
        background-color: #b92fb1;
      }
    }
  }
  &:nth-child(5) {
    .single-services-box-item {
      .icon {
        background-color: #de6742;
      }
    }
  }
  &:nth-child(6) {
    .single-services-box-item {
      .icon {
        background-color: #59eed0;
      }
    }
  }
}

/*================================================
Services Details CSS
=================================================*/
.services-details-desc {
  h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .services-details-accordion {
    .accordion {
      list-style-type: none;
      padding: 0;
      margin: 25px 0 0;
      border: none;

      .accordion__item {
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        background: $white-color;
        margin-bottom: 10px;
        border: none;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .accordion__button {
        display: block;
        padding: 15px 20px;
        text-decoration: none;
        color: $black-color;
        position: relative;
        background-color: #fff;

        font: {
          size: 15px;
          weight: 500;
        }
        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 40px;
          text-align: center;
          height: 100%;
          background: $light-green-color;
          color: $white-color;

          &::before {
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-size: 15px;
          }
        }
        &.active {
          border-bottom-color: #e1e1e1;

          i {
            &::before {
              content: "\f10f";
            }
          }
        }
      }
      .accordion__panel {
        padding: 15px 15px;
        border-top: 1px solid #e1e1e1;
      }
    }
  }
}
.services-details-area {
  .separate {
    margin-top: 80px;
  }
}
.services-details-image {
  text-align: center;
}

/*================================================
Features CSS
=================================================*/
.features-area-two {
  overflow: hidden;
}
.single-features {
  position: relative;
  background: $white-color;
  padding: {
    left: 95px;
    top: 30px;
    right: 20px;
    bottom: 30px;
  }
  margin-bottom: 30px;
  border-radius: 5px;
  transition: $transition;

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .icon {
    position: absolute;
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    border-radius: 50%;
    color: $light-green-color;
    left: 20px;
    top: 30px;
    transition: $transition;

    &.bg-c679e3 {
      color: $purple-color;
      background: #edc3fc;
    }
    &.bg-eb6b3d {
      color: #eb6b3d;
      background: rgba(235, 107, 61, 0.3);
    }
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .icon {
      background: $light-green-color;
      color: $white-color;

      &.bg-c679e3 {
        background: $purple-color;
      }
      &.bg-eb6b3d {
        background: #eb6b3d;
      }
    }
  }
}
.single-hosting-features {
  position: relative;
  background: $white-color;
  text-align: center;
  padding: {
    left: 20px;
    top: 30px;
    right: 20px;
    bottom: 30px;
  }
  margin-bottom: 30px;
  border-radius: 5px;
  transition: $transition;

  h3 {
    font-size: 18px;
    margin: {
      top: 25px;
      bottom: 15px;
    }
  }
  .icon {
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    display: inline-block;
    border-radius: 50%;
    color: $light-green-color;
    transition: $transition;

    &.bg-c679e3 {
      color: $purple-color;
      background: #edc3fc;
    }
    &.bg-eb6b3d {
      color: #eb6b3d;
      background: rgba(235, 107, 61, 0.3);
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    background: $light-green-color;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    transition: $transition;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .icon {
      background: $light-green-color;
      color: $white-color;

      &.bg-c679e3 {
        background: $purple-color;
      }
      &.bg-eb6b3d {
        background: #eb6b3d;
      }
    }
    &::after,
    &::before {
      opacity: 1;
      visibility: visible;
      width: 50%;
    }
  }
}

/*================================================
Features Details CSS
=================================================*/
.features-details-desc {
  h3 {
    font-size: 22px;
    margin-bottom: 13px;
  }
  .features-details-list {
    padding: 0;
    margin: 20px 0 0;
    list-style-type: none;

    li {
      margin-bottom: 12px;
      color: $paragraph-color;
      position: relative;
      padding-left: 15px;

      &::before {
        background: $light-green-color;
        width: 8px;
        height: 8px;
        left: 0;
        top: 6px;
        position: absolute;
        content: "";
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .features-details-accordion {
    .accordion {
      list-style-type: none;
      padding: 0;
      margin: 25px 0 0;
      border: none;

      .accordion__item {
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        background: $white-color;
        margin-bottom: 10px;
        border: none;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .accordion__button {
        display: block;
        padding: 15px 20px;
        text-decoration: none;
        color: $black-color;
        position: relative;
        background-color: #fff;

        font: {
          size: 15px;
          weight: 500;
        }
        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 40px;
          text-align: center;
          height: 100%;
          background: $light-green-color;
          color: $white-color;

          &::before {
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-size: 15px;
          }
        }
        &.active {
          border-bottom-color: #e1e1e1;

          i {
            &::before {
              content: "\f10f";
            }
          }
        }
      }
      .accordion__panel {
        padding: 15px 15px;
        border-top: 1px solid #e1e1e1;
      }
    }
  }
}
.features-details-area {
  .separate {
    margin-top: 80px;
  }
}
.features-details-image {
  text-align: center;
}

/*================================================
Team CSS
=================================================*/
.single-team {
  background: $white-color;
  padding: {
    top: 35px;
    bottom: 35px;
  }
  margin-bottom: 30px;
  transition: $transition;
  z-index: 1;
  text-align: center;

  .team-image {
    position: relative;
    z-index: 1;
    padding: {
      left: 35px;
      right: 35px;
    }

    img {
      border: 3px solid #cdf1d8;
      width: auto !important;
      border-radius: 50%;
      display: inline-block !important;
      transition: $transition;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      background: $bg-color2;
      height: 10px;
      width: 100%;
      z-index: -1;
      transition: $transition;
    }
  }
  .team-content {
    padding: {
      left: 35px;
      right: 35px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        display: inline-block;
        margin: 0 5px;

        a {
          color: $purple-color;

          &:hover {
            transform: translateY(-5px);
            color: $light-green-color;
          }
          svg {
            width: 19px;
          }
        }
      }
    }
    p {
      margin: {
        top: 15px;
        bottom: 0;
      }
    }
  }
  .team-info {
    background: $gradient-color;
    padding: 10px 15px;
    border-radius: 75px;
    margin: {
      bottom: 25px;
      left: 10px;
      right: 10px;
      top: 25px;
    }
    transition: $transition;

    h3 {
      font-size: 18px;
      margin-bottom: 5px;
      color: $white-color;
    }
    span {
      color: $white-color;
      font-size: 13px;
    }
  }
  &:hover {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .team-image {
      &::before {
        height: 0;
      }
      img {
        border-color: $light-green-color;
      }
    }
    .team-info {
      margin: {
        left: 0;
        right: 0;
      }
      border-radius: 0;
    }
  }
}

/*================================================
Works CSS
=================================================*/
.works-area {
  position: relative;
  z-index: 1;
}
.single-works {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $gradient-color;
    transition: $transition;
    opacity: 0;
    visibility: hidden;
  }
  .icon {
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: 25px;
    background: $white-color;
    width: 45px;
    text-align: center;
    height: 45px;
    line-height: 42px;
    opacity: 0;
    transition: $transition;
    visibility: hidden;

    svg {
      width: 19px;
    }
    &:hover {
      background: $black-color;
      color: $white-color;
    }
  }
  .works-content {
    position: absolute;
    bottom: -60px;
    left: 0;
    padding: 20px;
    transition: $transition;
    opacity: 0;
    visibility: hidden;

    h3 {
      font-size: 18px;
      margin-bottom: 15px;

      a {
        color: $white-color;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    p {
      color: $white-color;
    }
  }
  &:hover {
    &::before {
      opacity: 0.9;
      visibility: visible;
      z-index: 1;
    }
    .icon {
      opacity: 1;
      visibility: visible;
      top: 25px;
      z-index: 5;
    }
    .works-content {
      opacity: 1;
      visibility: visible;
      bottom: 0;
      z-index: 5;
    }
  }
}

/*================================================
Fun Facts CSS
=================================================*/
.funfacts-area {
  position: relative;
  z-index: 1;

  .section-title {
    h2 {
      max-width: 570px;
      margin: 0 auto;
      line-height: 37px;
    }
  }
  .map-bg {
    position: absolute;
    z-index: -1;
    top: 120px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
  }
}
.funfact {
  text-align: center;

  h3 {
    color: $light-green-color;
    font-size: 32px;
    margin-bottom: 7px;
  }
  p {
    line-height: initial;
  }
}
.contact-cta-box {
  margin: 80px auto 0;
  max-width: 700px;
  border: 1px dashed #ebebeb;
  padding: 30px 210px 30px 30px;
  border-radius: 5px;
  position: relative;

  h3 {
    font-size: 20px;
    margin-bottom: 6px;
  }
  p {
    margin-bottom: 0;
  }
  .btn {
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -27px;

    &::before,
    &::after {
      background: $light-green-color;
    }
  }
  .btn-primary {
    background: $purple-color;
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

    &:hover,
    &:focus {
      box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    }
  }
}
.funfacts-area {
  &.bg-image {
    position: relative;
    z-index: 1;
    background: {
      image: url(/default_images/funfacts-bg.jpg);
      position: center center;
      size: cover;
      repeat: no-repeat;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background-color: #011f43;
      opacity: 0.86;
    }
    .section-title {
      h2 {
        color: $white-color;
      }
      p {
        color: $white-color;
      }
    }
    .contact-cta-box {
      border-color: #406883;

      h3 {
        color: $white-color;
      }
      p {
        color: $white-color;
      }
    }
  }
}
.single-funfact {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 5px;

  h3 {
    color: $light-green-color;
    font-size: 32px;
    margin-bottom: 7px;
  }
  p {
    line-height: initial;
    color: $white-color;
  }
}

/*==========================================
Pricing CSS
===========================================*/
.pricing-area {
  position: relative;
  z-index: 1;
}
.pricing-table {
  text-align: center;
  background: $white-color;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding-bottom: 35px;
  transition: $transition;
  margin-bottom: 30px;

  .pricing-header {
    background: $white-color;
    position: relative;
    padding: 30px 20px;
    border: {
      radius: 5px 5px 0 0;
      bottom: 1px solid #eeeeee;
    }
    transition: $transition;
    margin-bottom: 30px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px 5px 0 0;
      background: $gradient-color;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      z-index: -1;
    }
    h3 {
      font-size: 18px;
      text-transform: uppercase;
      transition: $transition;
      margin-bottom: 0;
    }
  }
  .price {
    margin-bottom: 15px;

    span {
      color: $light-green-color;
      font: {
        size: 30px;
        weight: 600;
      }
      sup {
        font: {
          weight: 500;
        }
      }
      span {
        font: {
          size: 14px;
          weight: 500;
        }
      }
    }
  }
  .pricing-features {
    ul {
      padding: 0;
      list-style-type: none;
      margin-bottom: 30px;

      li {
        color: #b5b5b5;
        margin-bottom: 12px;
        text-decoration: line-through;

        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          color: $paragraph-color;
          text-decoration: none;
        }
      }
    }
  }
  &:hover {
    transform: translateY(-10px);

    .pricing-header {
      &::before {
        opacity: 1;
        visibility: visible;
      }
      h3 {
        color: $white-color;
      }
    }
  }
  &.active-plan {
    .pricing-header {
      &::before {
        opacity: 1;
        visibility: visible;
      }
      h3 {
        color: $white-color;
      }
    }
    .btn-primary {
      background: $purple-color;
      box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

      &::after,
      &::before {
        background: $light-green-color;
        box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
      }
    }
  }
}
.single-pricing-table {
  text-align: center;
  background: $white-color;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 35px;
  transition: $transition;
  margin-bottom: 30px;

  .pricing-header {
    i {
      transition: $transition;

      &::before {
        font-size: 45px;
      }
    }
    h3 {
      font: {
        size: 18px;
        weight: 500;
      }
      text-transform: uppercase;
      margin: 15px 0 20px;
    }
  }
  .price {
    margin-bottom: 20px;

    span {
      color: $light-green-color;
      font: {
        size: 30px;
        weight: 600;
      }
      sup {
        font: {
          weight: 500;
        }
      }
      span {
        font: {
          size: 14px;
          weight: 500;
        }
      }
    }
  }
  .pricing-features {
    ul {
      padding: 0;
      margin: 0 0 30px;
      list-style-type: none;

      li {
        color: $paragraph-color;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
        svg {
          width: 15px;
        }
      }
    }
  }
  &.active-plan {
    .btn-primary {
      background: $purple-color;
      box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

      &::after,
      &::before {
        background: $light-green-color;
        box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
      }
    }
  }
}
.tab {
  .tabs_item {
    display: none;

    &:first-child {
      display: block;
    }
    &:nth-child(2) {
      .pricing-box {
        background: linear-gradient(
          to right top,
          #fffcff,
          #fbfaff,
          #effbff,
          #e0fdff,
          #d5fefd
        );
      }
    }
  }
}
.pricing-tab {
  .react-tabs__tab-list {
    padding-left: 0;
    margin-bottom: 45px;
    list-style-type: none;
    text-align: center;

    li {
      display: inline-block;
      background: linear-gradient(
        to left bottom,
        #fffcff,
        #fbfaff,
        #effbff,
        #e0fdff,
        #d5fefd
      );
      padding: 10px 30px 9.5px;
      cursor: pointer;
      margin: {
        left: -2px;
        right: -2px;
      }
      font: {
        weight: 500;
        size: 15px;
      }
      &:hover {
        color: $black-color;
        background: linear-gradient(
          to left bottom,
          #fffcff,
          #fbfaff,
          #effbff,
          #e0fdff,
          #d5fefd
        );
      }
      &.react-tabs__tab--selected {
        color: $white-color;
        background: $black-color;
      }
      &:nth-child(1) {
        border-radius: 5px 0 0 5px;

        &::before {
          border-radius: 5px 0 0 5px;
        }
      }
      &:nth-child(2) {
        border-radius: 0 5px 5px 0;

        &::before {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
}
.pricing-box {
  background: linear-gradient(
    to left bottom,
    #fffcff,
    #fbfaff,
    #effbff,
    #e0fdff,
    #d5fefd
  );
  text-align: center;
  padding: 30px;
  transition: $transition;
  border-radius: 5px;
  margin-bottom: 30px;

  .pricing-header {
    h3 {
      margin-bottom: 0;
      font: {
        size: 20px;
      }
    }
    p {
      transition: $transition;
      line-height: 1.6;
      margin: {
        top: 10px;
        bottom: 0;
      }
    }
  }
  .price {
    font: {
      size: 35px;
      weight: 600;
    }
    margin: {
      bottom: 15px;
      top: 20px;
    }
    span {
      display: inline-block;
      margin-left: -7px;
      font: {
        size: 20px;
        weight: 600;
      }
    }
  }
  .buy-btn {
    margin-bottom: 30px;

    .btn {
      box-shadow: unset !important;
      transform: unset !important;
    }
    .btn-primary {
      border-radius: 30px;
      background: linear-gradient(
        to right top,
        #a3a3a3,
        #9a9a9a,
        #909090,
        #878787,
        #7e7e7e
      );
      text-transform: capitalize;
      padding: 11px 30px;
      font: {
        weight: 500;
      }
      &::after {
        border-radius: 30px;
        background: $light-green-color;
        width: 100%;
        opacity: 1;
        visibility: visible;
      }
      &::before {
        display: none;
      }
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      & .show > &.dropdown-toggle {
        background: linear-gradient(
          to right top,
          #a3a3a3,
          #9a9a9a,
          #909090,
          #878787,
          #7e7e7e
        );
      }
      &:hover,
      &:focus {
        &::after {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  .pricing-features {
    margin-bottom: 0;
    list-style-type: none;
    text-align: left;
    padding: {
      left: 15px;
      right: 15px;
    }
    li {
      margin-bottom: 12px;
      color: #495771;
      position: relative;
      padding-left: 27px;

      &:last-child {
        margin-bottom: 0;
      }
      svg {
        color: $light-green-color;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }
    }
  }
}

/*================================================
Domain Search CSS
=================================================*/
.domain-search-content {
  text-align: center;
  max-width: 750px;
  margin: 0 auto;

  h2 {
    text-transform: capitalize;
    margin-bottom: 30px;
    font: {
      size: 24px;
      weight: 600;
    }
  }
  form {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    background-color: $white-color;
    overflow: hidden;
    border-radius: 30px;

    .form-control {
      background-color: $white-color;
      border: none;
      height: 55px;
      padding-left: 20px;
      float: left;
      width: 63%;
    }
    .domain-select {
      float: left;
      width: 15%;
      padding-right: 20px;

      .form-select {
        padding: 0;
        float: unset;
        width: 100%;
        cursor: pointer;
        height: 55px;
        border: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
    button {
      box-shadow: unset !important;
      float: left;
      width: 22%;
      height: 55px;
      border-radius: 30px;
      background-color: $light-green-color;
      color: $white-color;
      font: {
        weight: 600;
        size: 14px;
      }
      outline: 0;
      cursor: pointer;
      border: none;
      padding: 15px 40px;
      text-transform: uppercase;
      transition: $transition;

      &:hover,
      &:focus {
        background-color: $purple-color;
      }
    }
  }
  ul {
    padding: 0;
    margin: 30px 0 0;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;

    li {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      font-weight: 500;
      border-left: 1px solid #eeeeee;

      &:first-child {
        border-left: none;
      }
    }
  }
}

/*================================================
Feedback CSS
=================================================*/
.feedback-area {
  position: relative;
  z-index: 1;
}
.feedback-slides {
  position: relative;
  max-width: 750px;
  margin: 0 auto;

  .client-feedback {
    position: relative;

    .single-feedback {
      background: $white-color;
      border-radius: 5px;
      padding: {
        left: 170px;
        right: 40px;
        top: 40px;
        bottom: 40px;
      }
      margin-bottom: 60px;
      position: relative;

      .client-img {
        position: absolute;
        left: 40px;
        top: 40px;

        img {
          border-radius: 50%;
          border: 2px solid $light-green-color;
          padding: 3px;
          width: auto !important;
          display: inline-block !important;
          width: 95px;
          height: 95px;
        }
      }
      h3 {
        font-size: 19px;
        margin: 0;
      }
      span {
        display: block;
        margin: {
          top: 8px;
          bottom: 15px;
        }
        color: $purple-color;
      }
      p {
        font-size: 15px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -25px;
        width: 50px;
        height: 50px;
        background: $white-color;
        right: 0;
        margin: 0 auto;
        transform: rotate(45deg);
      }
    }
  }
  .client-thumbnails {
    position: relative;
    margin: 0 85px;

    .item {
      .img-fill {
        cursor: pointer;
        position: relative;
        text-align: center;

        img {
          opacity: 0.4;
          transition: $transition;
          display: inline-block;
          position: relative;
          border: 2px solid $light-green-color;
          border-radius: 50%;
          padding: 3px;
          width: 85px;
        }
      }
      &:hover,
      &.slick-center {
        .img-fill {
          img {
            opacity: 1;
          }
        }
      }
    }

    .slick-center {
      .img-fill {
        img {
          opacity: 1;
        }
      }
    }
  }
}
.client-thumbnails {
  .next-arrow,
  .prev-arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    color: #5e5e5e;
    border: 2px solid #5e5e5e;
    z-index: 1;
    border-radius: 50%;
    line-height: 30px;
    outline: 0 !important;
    transition: $transition;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $gradient-color;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      border-radius: 50%;
    }
    &:hover {
      color: $white-color;
      border-color: $light-green-color;

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
    svg {
      width: 18px;
      height: 18px;
      position: relative;
    }
  }
  .next-arrow {
    right: -20px;
  }
  .prev-arrow {
    left: -20px;
  }
}
.testimonials-slides-box {
  position: relative;
  &::before {
    position: absolute;
    left: 150px;
    top: -30px;
    width: 260px;
    height: 260px;
    content: "";
    z-index: -1;
    background-image: url(/default_images/layer.png);
  }
  &::after {
    position: absolute;
    right: 150px;
    bottom: -30px;
    width: 260px;
    height: 260px;
    content: "";
    z-index: -1;
    background-image: url(/default_images/layer.png);
  }
}
.testimonials-slides {
  background-color: #f7fbfe;
  max-width: 850px;
  margin: 90px auto 30px;
  position: relative;
  z-index: 1;

  .swiper-pagination {
    position: absolute;
    bottom: 40px;
  }
}
.single-feedback-item {
  padding: 40px 130px 90px;
  text-align: center;
  background-color: #f7fbfe;
  position: relative;

  .client-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: left;
    max-width: 280px;
    margin: 0 auto 25px;

    .image {
      padding-right: 20px;

      img {
        display: inline-block !important;
        width: 75px;
        border-radius: 50%;
        border: 3px solid $light-green-color;
      }
    }
    .title {
      h3 {
        font-size: 18px;
        margin: 0;
      }
      span {
        display: block;
        margin-top: 8px;
        color: $purple-color;
      }
    }
  }
  p {
    font-size: 16px;
    font-style: italic;
  }
  &::before {
    content: "\f11b";
    color: #d9dcde;
    font: {
      family: Flaticon;
      size: 30px;
      style: normal;
      size: 65px;
    }
    position: absolute;
    top: 0;
    left: 25px;
  }
}
.agency-portfolio-feedback-slides {
  max-width: 730px;
  margin: {
    left: auto;
    right: auto;
  }
}
.agency-portfolio-feedback-item {
  margin-bottom: 20px;
  text-align: center;

  img {
    width: auto !important;
    display: inline-block !important;
    border-radius: 50%;
    border: 3px solid #999999;
  }
  p {
    font-size: 16px;
    margin: {
      top: 20px;
      bottom: 0;
    }
  }
}
.bigdata-feedback-area {
  background-color: #eef6fd;
}
.single-feedback-box {
  position: relative;
  margin: {
    left: 25px;
    top: 25px;
    bottom: 30px;
  }
  .feedback-desc {
    background-color: $white-color;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    padding: {
      left: 30px;
      bottom: 30px;
      right: 30px;
      top: 40px;
    }
    p {
      margin-bottom: 0;
      position: relative;
      color: $black-color;

      &::before {
        content: '"';
        position: relative;
        display: inline-block;
        left: 0;
        color: $black-color;
      }
      &::after {
        content: '"';
        position: relative;
        display: inline-block;
        right: 0;
        color: $black-color;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 35px;
      bottom: -12px;
      width: 25px;
      height: 25px;
      z-index: -1;
      background: $white-color;
      transform: rotate(45deg);
    }
  }
  &::before {
    transition: $transition;
    content: "\f11b";
    position: absolute;
    left: 0;
    top: -25px;
    left: -25px;
    color: $white-color;
    background: linear-gradient(135deg, #43e794 0%, #23bdb8 100%);
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    z-index: 2;
    text-align: center;
    font: {
      family: Flaticon;
      size: 30px;
    }
  }
  .client-info {
    position: relative;
    padding-left: 65px;
    margin: {
      top: 32px;
      bottom: 25px;
      left: 27px;
    }
    img {
      width: 50px !important;
      height: 50px !important;
      display: inline-block !important;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: $transition;
    }
    h3 {
      margin-bottom: 0;
      font: {
        size: 18px;
        weight: 600;
      }
    }
    span {
      display: block;
      color: $paragraph-color;
      margin-top: 6px;
    }
  }
}

/*================================================
Ready To Talk CSS
=================================================*/
.ready-to-talk {
  text-align: center;
  position: relative;
  padding: {
    top: 80px;
    bottom: 160px;
  }
  background: $gradient-color;

  h3 {
    font-size: 28px;
    color: $white-color;
    text-transform: capitalize;
    margin-bottom: 15px;
  }
  p {
    color: $white-color;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .btn {
    &::before,
    &::after {
      background: $white-color;
    }
  }
  .btn-primary {
    background: $purple-color;
    box-shadow: 0 5px 20px 0 rgba(198, 121, 227, 0.25);

    &:hover,
    &:focus {
      color: $black-color;
      box-shadow: 0 5px 20px 0 rgba(68, 206, 111, 0.25);
    }
  }
  span {
    a {
      margin-left: 15px;
      color: $white-color;
      text-decoration: underline;
    }
  }
}

/*=============================
Big Data Analytics Banner CSS
===============================*/
.bigdata-analytics-banner {
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  padding: {
    top: 280px;
    bottom: 25px;
  }
}
.bigdata-analytics-content {
  max-width: 555px;

  h1 {
    color: $white-color;
    margin-bottom: 25px;
    line-height: 1.4;
    font: {
      size: 50px;
      weight: 700;
    }
  }
  p {
    color: #e9e9e9;
    max-width: 470px;
    font: {
      size: 15px;
    }
  }
  .btn {
    margin-top: 18px;
  }
}
.banner-boxes-area {
  margin-top: 230px;
}
.single-banner-boxes {
  margin-bottom: 30px;
  border: 1px dashed #687b6f;
  position: relative;
  border-radius: 5px;
  padding: 25px 25px 25px 95px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: $transition;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    z-index: -1;
    opacity: 0;
    margin: -1px;
    visibility: hidden;
    transition: $transition;
  }
  .icon {
    display: inline-block;
    text-align: center;
    width: 55px;
    height: 55px;
    position: absolute;
    left: 20px;
    top: 25px;
    line-height: 55px;
    background: #cdf1d8;
    border-radius: 50%;
    color: $light-green-color;
    transition: $transition;
  }
  h3 {
    color: $white-color;
    margin-bottom: 12px;
    font: {
      size: 18px;
      weight: 600;
    }
  }
  p {
    color: #dadada;
    transition: $transition;
  }
  &:hover {
    background-color: transparent;
    border-color: transparent;

    .icon {
      color: $light-green-color;
      background-color: $white-color;
    }
    &::before {
      opacity: 1;
      visibility: visible;
    }
    p {
      color: $white-color;
    }
  }
}
.col-lg-4 {
  &:nth-child(2) {
    .single-banner-boxes {
      .icon {
        color: #f78acb;
        background: #f9f4f7;
      }
      &:hover {
        .icon {
          background-color: $white-color;
          color: #f78acb;
        }
      }
    }
  }
  &:nth-child(3) {
    .single-banner-boxes {
      .icon {
        color: $purple-color;
        background: #edc3fc;
      }
      &:hover {
        .icon {
          background-color: $white-color;
          color: $purple-color;
        }
      }
    }
  }
}

/*=============================
What We Do CSS
===============================*/
.what-we-do-area {
  padding-bottom: 50px;
}
.single-what-we-do-box {
  margin-bottom: 30px;
  position: relative;
  padding-left: 88px;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 65px;
    height: 65px;
    border-radius: 5px;
    color: $white-color;
    text-align: center;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);

    i {
      position: absolute;
      left: 0;
      right: 0;
      top: 40%;
      transform: translateY(-40%);

      &::before {
        font-size: 40px;
      }
    }
  }
  h3 {
    margin-bottom: 10px;
    font: {
      size: 20px;
      weight: 600;
    }
  }
  .link {
    display: block;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 10px;
  }
  .read-more-btn {
    display: inline-block;
    font-weight: 600;
    padding: {
      top: 10px;
      left: -4px;
    }
    svg {
      margin-right: 5px;
    }
  }
}

/*=============================
Discover CSS
===============================*/
.discover-area {
  position: relative;
  z-index: 1;
  background: linear-gradient(135deg, #43e794 0%, #23bdb8 100%);
}
.discover-image {
  position: relative;
  padding-bottom: 100px;
  margin-right: 15px;

  img {
    &:nth-child(2) {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
.discover-content {
  padding-left: 15px;

  h2 {
    color: $white-color;
    margin-bottom: 15px;
    line-height: 1.5;
    font: {
      size: 28px;
      weight: 600;
    }
  }
  p {
    color: $white-color;
  }
  .btn-primary {
    box-shadow: unset !important;
    margin-top: 15px;
    background-color: $white-color;
    color: $black-color;

    &:hover {
      color: $white-color;
    }
  }
}
.analytics-shape1 {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.analytics-shape2 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

/*================================================
Partner CSS
=================================================*/
.partner-section {
  text-align: center;
  position: relative;
  margin-top: -120px;
  background: transparent;

  h5 {
    font: {
      size: 20px;
      weight: 400;
    }
    color: $white-color;
    margin-bottom: 0;
  }
  .partner-inner {
    background: $white-color;
    padding: {
      top: 5px;
      left: 30px;
      right: 30px;
      bottom: 30px;
    }
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    margin-top: 25px;

    a {
      display: block;
      margin-top: 25px;
      position: relative;

      img {
        &:last-child {
          position: absolute;
          top: 15px;
          left: 0;
          right: 0;
          margin: 0 auto;
          opacity: 0;
          visibility: hidden;
          transition: $transition;
        }
      }
      &:hover {
        img {
          &:last-child {
            opacity: 1;
            top: 0;
            visibility: visible;
          }
        }
      }
    }
  }
}

/*================================================
Industries Serve CSS
=================================================*/
.industries-serve-area {
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  background: linear-gradient(135deg, #43e794 0%, #23bdb8 100%);

  .section-title {
    h2 {
      color: $white-color;
    }
    p {
      color: $white-color;
    }
    .bar {
      &::before {
        background: $white-color;
      }
    }
  }
}
.single-industries-serve-box {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  color: $black-color;
  border-radius: 5px;
  background-color: #f4f4fb;
  padding: 20px 15px 20px 50px;
  transition: $transition;
  z-index: 1;
  font: {
    size: 18px;
    weight: 600;
  }
  .icon {
    position: absolute;
    left: -15px;
    top: 50%;
    line-height: 1;
    transform: translateY(-50%);
    color: #c3c3c9;
    transition: $transition;
    font: {
      size: 55px;
      weight: normal;
    }
  }
  &::before {
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    content: "";
    z-index: -1;
    transition: $transition;
    background-color: $black-color;
  }
  .link-btn {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    z-index: 2;
  }
  &:hover {
    color: $white-color;

    .icon {
      color: $white-color;
    }
    &::before {
      width: 100%;
    }
  }
}

/*================================================
Blog CSS
=================================================*/
.blog-area {
  position: relative;
  z-index: 1;
}
.single-blog-post {
  position: relative;
  transition: $transition;
  margin-bottom: 30px;

  .blog-image {
    position: relative;

    a {
      display: block;
    }
    .date {
      position: absolute;
      left: 20px;
      bottom: -20px;
      background: $gradient-color;
      color: $white-color;
      padding: 8px 16px;
      font-size: 13px;
      border-radius: 50px;

      svg {
        width: 15px;
        margin-right: 3px;
        height: auto;
      }
    }
  }
  .blog-post-content {
    padding: {
      top: 40px;
      left: 20px;
      bottom: 20px;
      right: 20px;
    }
    background: $bg-color;
    transition: $transition;

    h3 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0;
    }
    span {
      display: block;
      color: $black-color;
      margin: 15px 0;

      a {
        color: $paragraph-color;

        &:hover {
          color: $light-green-color;
        }
      }
    }
    .read-more-btn {
      color: $black-color;

      svg {
        transition: $transition;
        width: 15px;
      }
      &:hover {
        color: $light-green-color;

        svg {
          margin-left: 5px;
        }
      }
    }
  }
  &:hover {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .blog-post-content {
      background: $white-color;
    }
  }
}
.single-blog-post-box {
  margin-bottom: 30px;
  transition: $transition;
  border-radius: 5px;

  .entry-thumbnail {
    a {
      display: block;

      img {
        border-radius: 5px 5px 0 0;
      }
    }
  }
  .entry-post-content {
    background: linear-gradient(
      to right top,
      #d5fefd,
      #e0fdff,
      #effbff,
      #fbfaff,
      #fffcff
    );
    padding: 20px;

    .entry-meta {
      margin-bottom: 10px;

      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          display: inline-block;
          position: relative;
          margin-right: 16px;
          color: $paragraph-color;
          font: {
            size: 14px;
          }
          &::before {
            content: "";
            position: absolute;
            right: -7px;
            top: 50%;
            transform: rotate(14deg) translateY(-50%);
            background: $paragraph-color;
            width: 1px;
            height: 13px;
          }
          a {
            display: inline-block;
            color: $paragraph-color;

            &:hover {
              color: $light-green-color;
            }
          }
          &:last-child {
            margin-right: 0;

            &::before {
              display: none;
            }
          }
        }
      }
    }
    h3 {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: 600;
      font: {
        size: 18px;
      }
    }
    p {
      margin: {
        top: 8px;
        bottom: 0;
      }
    }
    .learn-more-btn {
      margin-top: 15px;
      font-weight: 600;

      svg {
        width: 15px;
      }
    }
  }
}
.single-blog-item {
  margin-bottom: 30px;

  .post-image {
    text-align: center;

    a {
      display: block;
    }
  }
  .post-content {
    margin-top: 20px;

    .post-meta {
      padding-left: 0;
      margin-bottom: 10px;
      list-style-type: none;

      li {
        display: inline-block;
        margin-right: 30px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 10px;
          right: -20px;
          width: 10px;
          height: 1px;
          background-color: $paragraph-color;
        }
        &:last-child {
          margin-right: 0;

          &::before {
            display: none;
          }
        }
      }
    }
    h3 {
      line-height: 30px;
      margin-bottom: 0;
      font: {
        weight: 600;
        size: 20px;
      }
    }
  }
}
.single-blog-post-item {
  margin-bottom: 30px;

  .post-image {
    border-radius: 5px;
    overflow: hidden;

    a {
      display: block;
      border-radius: 5px;

      img {
        border-radius: 5px;
      }
    }
  }
  .post-content {
    transition: $transition;
    background: linear-gradient(
      to right top,
      #d5fefd,
      #e0fdff,
      #effbff,
      #fbfaff,
      #fffcff
    );
    position: relative;
    border-radius: 5px;
    padding: 30px 25px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.05);
    margin: {
      left: 15px;
      right: 15px;
      top: -30px;
    }
    .post-meta {
      transition: $transition;
      margin-bottom: 10px;
      list-style-type: none;
      padding-left: 0;

      li {
        display: inline-block;
        margin-right: 20px;
        position: relative;
        color: $paragraph-color;

        a {
          color: $paragraph-color;

          &:hover {
            color: $light-green-color;
          }
        }
        &::before {
          content: "";
          position: absolute;
          right: -10px;
          top: 4px;
          height: 12px;
          background-color: $paragraph-color;
          width: 1px;
          transform: rotate(5deg);
        }
        &:last-child {
          margin-right: 0;

          &::before {
            display: none;
          }
        }
      }
    }
    h3 {
      transition: $transition;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0;
    }
    .read-more-btn {
      display: inline-block;
      border-radius: 30px;
      padding: 9px 25px;
      border: 1px solid #e6e6e6;
      background-color: $white-color;
      position: absolute;
      transition: $transition;
      left: 25px;
      bottom: -22px;
      opacity: 0;
      visibility: hidden;
      font: {
        size: 14px;
        weight: 500;
      }
      svg {
        width: 15px;
        margin-left: 2px;
      }
      &:hover {
        background-color: $light-green-color;
        border-color: $light-green-color;
        color: $white-color;
      }
    }
  }
  &:hover {
    .post-content {
      transform: translateY(-22px);

      .post-meta {
        transform: translateY(-12px);
      }
      h3 {
        transform: translateY(-12px);
      }
      .read-more-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.single-blog-post-box {
  &.bg-f4faff {
    .entry-post-content {
      background: #f4faff;
    }
  }
}

/*================================================
Shop CSS
=================================================*/
.woocommerce-topbar {
  background-color: #fcfbfb;
  padding: 10px 40px;
  margin-bottom: 30px;

  .woocommerce-topbar-ordering {
    .form-select {
      cursor: pointer;
      background-color: transparent;
      border-color: #ebebeb;
      border-radius: 4px;
      font-size: 14px;
      height: 40px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}
.single-products {
  text-align: center;
  position: relative;
  margin-bottom: 30px;

  .products-content {
    margin-top: 20px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    ul {
      padding: 0;
      margin: 10px 0 12px;
      list-style-type: none;

      li {
        display: inline-block;
        color: #eac11d;

        i {
          margin: 0 2px;
          &::before {
            font-size: 15px;
          }
        }
      }
    }
    .add-to-cart-btn {
      display: block;
      width: 100%;
      color: $black-color;
      border: 1px solid $black-color;
      padding: 10px 15px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .sale-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #ff2d2d;
    color: #fff;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    z-index: 2;
  }
  .products-image {
    overflow: hidden;
    position: relative;

    img {
      transition: all 1.5s ease-out;
      width: 100%;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: gray;
      content: "";
      transition: $transition;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
    }
    ul {
      padding: 0;
      z-index: 2;
      list-style-type: none;
      margin: 0;
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      opacity: 0;
      visibility: hidden;
      transition: $transition;

      li {
        display: inline-block;
        margin: 0 2px;

        a,
        button {
          display: inline-block;
          background: $white-color;
          width: 35px;
          height: 35px;
          line-height: 35px;
          border: none;
          padding: 0;

          svg {
            width: 20px;
          }
          &:hover,
          &:focus {
            background-color: $light-green-color;
            color: $white-color;
          }
        }
      }
    }
  }
  &:hover,
  &:focus {
    .products-content {
      .add-to-cart-btn {
        background-color: $black-color;
        color: $white-color;
      }
    }
    .products-image {
      img {
        transform: scale(1.1);
      }
      &::before {
        opacity: 0.4;
        visibility: visible;
      }
      ul {
        opacity: 1;
        visibility: visible;

        li {
          a {
            &:nth-child(1) {
              margin-left: 0;
            }
            &:nth-child(3) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
#productsModalCenter {
  .modal-dialog {
    max-width: 900px;
  }
  .modal-content {
    border: none;
    border-radius: 0;

    button.close {
      position: absolute;
      right: 2%;
      display: inline-block;
      opacity: 1;
      top: 2%;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 32px;
      border: 1px solid #eeeeee;
      border-radius: 50%;
      outline: 0;
      transition: $transition;

      span {
        transition: $transition;
      }
      &:hover,
      &:focus {
        background-color: $light-green-color;
        color: $white-color;

        span {
          color: $white-color;
        }
      }
    }
    .products-image {
      overflow: hidden;

      img {
        width: 100%;
      }
    }
    .products-content {
      p {
        margin-bottom: 20px;
      }
      h3 {
        font-size: 20px;
        margin-bottom: 10px;
      }
      .price {
        margin-bottom: 15px;

        span {
          text-decoration: line-through;
          color: $paragraph-color;
        }
      }
      form {
        .quantity {
          display: inline-block;
          margin-right: 8px;

          .input-counter {
            max-width: 110px;
            min-width: 110px;
            text-align: center;
            display: inline-block;
            position: relative;

            span {
              position: absolute;
              top: 0;
              background-color: transparent;
              cursor: pointer;
              color: $paragraph-color;
              width: 40px;
              height: 100%;
              line-height: 47px;
              transition: $transition;
              padding: 0;

              &.minus-btn {
                left: 0;
              }
              &.plus-btn {
                right: 0;
              }
              svg {
                width: 20px;
              }
              &:hover {
                color: $light-green-color;
              }
            }
            input {
              height: 47px;
              color: $black-color;
              outline: 0;
              display: block;
              border: none;
              background-color: #f8f8f8;
              text-align: center;
              width: 100%;
              font: {
                size: 17px;
                weight: 500;
              }
              &::placeholder {
                color: $black-color;
              }
            }
          }
        }
        button {
          background: $light-green-color;
          color: $white-color;
          border: none;
          height: 46px;
          padding: 0 20px;
          position: relative;
          top: -2px;
          cursor: pointer;
          outline: 0;
          transition: $transition;

          &:hover,
          &:focus {
            background-color: $purple-color;
            color: $white-color;
          }
        }
      }
      .product-meta {
        margin-top: 15px;

        span {
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
  }
}

/*================================================
Shop Details CSS
=================================================*/
.products-details-image {
  img {
    width: 100%;
  }
  .swiper-button-next,
  .swiper-button-prev {
    &:after {
      color: $light-green-color;
    }
  }
}
.products-details {
  h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  .price {
    margin-bottom: 15px;
    font-size: 17px;

    span {
      text-decoration: line-through;
      color: $paragraph-color;
      margin-right: 5px;
    }
  }
  .rating {
    padding: 0;
    margin: 0 0 12px;
    list-style-type: none;

    li {
      display: inline-block;
      color: #eac11d;

      i {
        margin-right: 3px;
        &::before {
          font-size: 15px;
        }
      }
    }
  }
  .payment-methods {
    img {
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .availability {
    margin-bottom: 20px;

    span {
      color: $light-green-color;
    }
  }
  form {
    .quantity {
      margin-bottom: 20px;

      span {
        padding-right: 10px;
      }
      .input-counter {
        max-width: 110px;
        min-width: 110px;
        text-align: center;
        display: inline-block;
        position: relative;

        span {
          position: absolute;
          top: 0;
          background-color: transparent;
          cursor: pointer;
          color: $paragraph-color;
          width: 40px;
          height: 100%;
          line-height: 47px;
          transition: $transition;
          padding: 0;

          &.minus-btn {
            left: 0;
          }
          &.plus-btn {
            right: 0;
          }
          svg {
            width: 20px;
          }
          &:hover {
            color: $light-green-color;
          }
        }
        input {
          height: 47px;
          color: $black-color;
          outline: 0;
          display: block;
          border: none;
          background-color: #f8f8f8;
          text-align: center;
          width: 100%;
          font: {
            size: 17px;
            weight: 500;
          }
          &::placeholder {
            color: $black-color;
          }
        }
      }
    }
    button {
      background: $light-green-color;
      color: $white-color;
      border: none;
      height: 46px;
      padding: 0 20px;
      position: relative;
      top: -3px;
      cursor: pointer;
      outline: 0;
      transition: $transition;

      &:hover,
      &:focus {
        background-color: $purple-color;
        color: $white-color;
      }
    }
    .add-to-wishlist-btn {
      margin-left: 5px;
      background: transparent;
      color: $black-color;
      border: 1px solid #eeeeee;
      position: relative;
      top: -2px;
      cursor: pointer;
      outline: 0;
      transition: $transition;
      width: 45px;
      height: 45px;
      text-align: center;
      line-height: 45px;

      &:hover,
      &:focus {
        background-color: $purple-color;
        color: $white-color;
        border-color: $purple-color;
      }
    }
    .buy-btn {
      margin-top: 20px;

      .btn-primary {
        background: $purple-color;
        box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

        &::after,
        &::before {
          background: $light-green-color;
          box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
        }
      }
    }
  }
  .custom-payment-options {
    margin-top: 20px;

    span {
      display: block;
      margin-bottom: 10px;
    }
  }
  .products-share-social {
    margin-top: 25px;

    span {
      display: inline-block;
      margin-right: 10px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: inline-block;

      li {
        display: inline-block;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }

        a {
          width: 30px;
          text-align: center;
          line-height: 27px;
          height: 30px;
          border: 1px solid $light-green-color;
          border-radius: 50%;
          color: $light-green-color;

          svg {
            width: 15px;
          }
          &.facebook {
            border-color: #4267b2;
            color: #4267b2;

            &:hover {
              background: #4267b2;
              color: $white-color;
            }
          }
          &.twitter {
            border-color: #38a1f3;
            color: #38a1f3;

            &:hover {
              background: #38a1f3;
              color: $white-color;
            }
          }
          &.linkedin {
            border-color: #0077b5;
            color: #0077b5;

            &:hover {
              background: #0077b5;
              color: $white-color;
            }
          }
          &.instagram {
            border-color: #231f20;
            color: #231f20;

            &:hover {
              background: #231f20;
              color: $white-color;
            }
          }
          &:hover {
            background: $light-green-color;
            color: $white-color;
          }
        }
      }
    }
  }
}
.products-details-tabs {
  margin-top: 40px;
  text-align: center;

  .react-tabs__tab-list {
    padding: 0;
    margin: 0 0 30px;
    list-style-type: none;

    li {
      display: inline-block;
      padding: 12px 50px 8px;
      cursor: pointer;
      margin-right: -4px;
      transition: $transition;
      background: #eaeaea;
      font: {
        weight: 500;
      }
      &.active,
      &.react-tabs__tab--selected {
        background-color: $white-color;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          top: 0;
          left: 0;
          background: $light-green-color;
        }
      }
      &.inactive {
        background-color: #eeeeee;
        padding: 12px 50px;
      }
    }
  }
  .react-tabs__tab-panel {
    h3 {
      margin-bottom: 12px;
      font: {
        size: 18px;
      }
    }
    .additional-information {
      padding: 0;
      margin: 25px 0 0;
      list-style-type: none;
      text-align: left;

      li {
        border: 1px solid #eeeeee;
        border-bottom: none;
        padding: 10px 15px;
        color: $paragraph-color;

        &:last-child {
          border-bottom: 1px solid #eeeeee;
        }
        span {
          display: inline-block;
          width: 45%;
        }
      }
    }
    .products-reviews {
      text-align: left;
      max-width: 700px;
      margin: 0 auto;

      p {
        margin-bottom: 0;
      }
      .review-form {
        margin-top: 20px;
        max-width: 620px;

        p {
          margin-bottom: 0;
        }
        .form-control {
          background: transparent;
        }
      }
      .star-rating {
        margin-bottom: 10px;
        i {
          color: #eac11d;
        }
      }
    }
  }
}
@keyframes scaleup {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
@keyframes starred {
  from {
    color: #f2b01e;
  }
  to {
    color: #f2b01e;
  }
}

/*================================================
Cart CSS
=================================================*/
.cart-table {
  table {
    margin-bottom: 0;

    thead {
      tr {
        th {
          border-bottom-width: 0px;
          vertical-align: middle;
          font-weight: 500;
          padding: 15px 0;
          font-size: 15px;
          border: none;
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          color: $paragraph-color;
          padding: {
            left: 0;
            right: 0;
          }
          border: {
            color: #eaedff;
            left: none;
            right: none;
          }
          &.product-thumbnail {
            a {
              display: block;

              img {
                width: 80px;
              }
            }
          }
          &.product-name {
            a {
              color: $paragraph-color;
              font-weight: 500;
              display: inline-block;

              &:hover {
                color: $light-green-color;
              }
            }
          }
          &.product-subtotal {
            .remove {
              color: red;
              margin-left: 25px;
              position: relative;
              top: -1px;
              float: right;

              svg {
                width: 19px;
              }
            }
          }
          &.product-quantity {
            .input-counter {
              max-width: 110px;
              min-width: 110px;
              text-align: center;
              display: inline-block;
              position: relative;

              span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: $paragraph-color;
                width: 40px;
                height: 100%;
                line-height: 47px;
                transition: $transition;

                &.minus-btn {
                  left: 0;
                }
                &.plus-btn {
                  right: 0;
                }
                svg {
                  width: 20px;
                }
                &:hover {
                  color: $light-green-color;
                }
              }
              input {
                height: 45px;
                color: $black-color;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                  size: 17px;
                  weight: 500;
                }
                &::placeholder {
                  color: $black-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
.cart-buttons {
  margin-top: 30px;

  .btn-light {
    background-color: #eaedff;

    &:hover,
    &:focus {
      color: $white-color;

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show > .btn-light.dropdown-toggle {
        color: $white-color;
      }
    }
    &:focus {
      color: $white-color;
    }
  }
}
.cart-totals {
  background: $white-color;
  padding: 40px;
  max-width: 600px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin: 45px auto 0;

  h3 {
    font: {
      size: 18px;
    }
    margin-bottom: 25px;
  }
  ul {
    padding: 0;
    margin: 0 0 25px;
    list-style-type: none;

    li {
      border: 1px solid #eaedff;
      padding: 10px 15px;
      color: $black-color;
      overflow: hidden;

      &:first-child {
        border: {
          bottom: none;
        }
      }
      &:last-child {
        border: {
          top: none;
        }
      }
      span {
        float: right;
        color: $paragraph-color;
      }
    }
  }
}

/*================================================
Checkout CSS
=================================================*/
.user-actions {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: $white-color;
  padding: 18px 25px 15px;
  margin-bottom: 35px;
  border-top: 3px solid $light-green-color;

  svg {
    color: $light-green-color;
    margin-right: 4px;
    width: 16px;
  }
  span {
    display: inline-block;
    color: $light-green-color;

    a {
      font-weight: 500;
      display: inline-block;
      color: $black-color;

      &:hover,
      &:focus {
        color: $light-green-color;
      }
    }
  }
}
.checkout-area {
  .title {
    font: {
      size: 18px;
    }
    margin-bottom: 25px;
  }
}
.billing-details {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: $white-color;
  padding: 25px;
  border-radius: 5px;

  .form-check-input {
    margin-top: 3px;
  }

  .form-group {
    label {
      display: block;
      color: $black-color;
      margin-bottom: 8px;
      font: {
        size: 14px;
        weight: 400;
      }
      .required {
        color: red;
      }
    }
    .form-control {
      background: transparent;
    }
    select {
      cursor: pointer;
    }
  }
  .form-check {
    margin-bottom: 15px;

    .form-check-label {
      color: $paragraph-color;
    }
    label {
      font: {
        size: 14px;
        weight: 400;
      }
    }
  }
  .form-select {
    height: 45px;
    padding: 10px;
    font-size: 14px;
    color: #0e314c;
    border: 1px solid #eeeeee;
    border-radius: 0;
    transition: 0.5s;
  }
}
.order-details {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: $white-color;
  padding: 25px;
  border-radius: 5px;

  .order-table {
    table {
      margin-bottom: 0;
      text-align: left;

      thead {
        tr {
          th {
            border-bottom-width: 0px;
            vertical-align: middle;
            font: {
              weight: 500;
            }
            border-color: #eaedff;
            padding: 15px 20px;
          }
        }
      }
      tbody {
        tr {
          td {
            vertical-align: middle;
            color: $paragraph-color;
            border-color: #eaedff;
            font-size: 14px;
            padding: 15px 20px;

            &.product-name {
              a {
                color: $black-color;
                display: inline-block;

                &:hover {
                  color: $light-green-color;
                }
              }
            }
            &.order-subtotal,
            &.order-shipping,
            &.total-price {
              span {
                color: $black-color;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
  .payment-method {
    margin-top: 40px;
    border-top: 1px solid #eaedff;
    padding-top: 35px;

    p {
      font: {
        size: 14px;
      }
      [type="radio"] {
        &:checked,
        &:not(:checked) {
          display: none;
        }
      }
      [type="radio"] {
        &:checked,
        &:not(:checked) {
          + label {
            padding-left: 27px;
            cursor: pointer;
            display: block;
            color: $black-color;
            position: relative;
            margin-bottom: 8px;
            font: {
              weight: 500;
            }
            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 4px;
              width: 18px;
              height: 18px;
              border: 1px solid #dddddd;
              border-radius: 50%;
              background: $white-color;
            }
            &::after {
              content: "";
              width: 12px;
              height: 12px;
              background: $light-green-color;
              position: absolute;
              top: 7px;
              left: 3px;
              border-radius: 50%;
              transition: $transition;
            }
          }
        }
      }
      [type="radio"] {
        &:not(:checked) {
          + label {
            &::after {
              opacity: 0;
              visibility: hidden;
              transform: scale(0);
            }
          }
        }
      }
      [type="radio"] {
        &:checked {
          + label {
            &::after {
              opacity: 1;
              visibility: visible;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  .btn {
    &.order-btn {
      display: block;
      margin-top: 25px;
    }
  }
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  background: transparent url(/default_images/banner-bg1.jpg) right top
    no-repeat;
  padding: {
    top: 190px;
    bottom: 100px;
  }
  text-align: center;

  h2 {
    font: {
      size: 28px;
      weight: 700;
    }
    margin: 0;
  }
}

/*================================================
FAQ CSS
=================================================*/
.faq-accordion {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  background: $white-color;
  border-radius: 10px;

  .accordion {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: none;

    .accordion__item {
      box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
      background: $white-color;
      margin-bottom: 15px;
      border: none;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .accordion__button {
      display: block;
      padding: 15px 20px;
      text-decoration: none;
      color: $black-color;
      position: relative;
      background-color: #fff;

      font: {
        size: 15px;
        weight: 500;
      }
      i {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        text-align: center;
        height: 100%;
        background: $light-green-color;
        color: $white-color;

        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          font-size: 15px;
        }
      }
      &.active {
        border-bottom-color: #e1e1e1;

        i {
          &::before {
            content: "\f10f";
          }
        }
      }
    }
    .accordion__panel {
      padding: 15px 15px;
      border-top: 1px solid #e1e1e1;
    }
  }
}
.faq-contact {
  margin-top: 45px;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  border-radius: 4px;
  background: $white-color;

  h3 {
    font: {
      size: 24px;
    }
    margin-bottom: 25px;
  }
  form {
    .btn {
      margin-top: 10px;
    }
  }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  text-align: center;
  margin-top: 30px;

  ul {
    padding: 0;
    margin: 0;

    .page-item {
      .page-link {
        padding: 11px 20px;
        margin: 0 -1px;
        color: #6f8ba4;
        background-color: $white-color;
        border: none;
        border-radius: 0;
        box-shadow: 0 2px 10px 0 #d8dde6;

        &:hover,
        &:focus {
          color: $white-color;
          background-color: $light-green-color;
          box-shadow: unset;
        }
        svg {
          width: 16px;
        }
      }
      &.active {
        .page-link {
          color: $white-color;
          background-color: $light-green-color;
        }
      }
    }
  }
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details-desc {
  .article-content {
    margin-top: 30px;

    .entry-meta {
      margin-bottom: 10px;

      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          position: relative;
          display: inline-block;
          color: $black-color;
          margin-right: 20px;

          a {
            display: inline-block;
            color: $paragraph-color;

            &:hover {
              color: $light-green-color;
            }
          }
          svg {
            color: $purple-color;
            margin-right: 2px;
            position: relative;
            top: -1px;
            width: 15px;
          }
          &::before {
            content: "";
            position: absolute;
            top: 12px;
            right: -15px;
            width: 6px;
            height: 1px;
            background: $light-green-color;
          }
          &:last-child {
            margin-right: 0;

            &::before {
              display: none;
            }
          }
        }
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 15px;
    }
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 25px;
    }
    h4 {
      font-size: 22px;
    }
    h5 {
      font-size: 20px;
    }
    .block-gallery {
      &.columns-3 {
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
          right: -10px;
          left: -10px;
          bottom: 30px;
          top: 30px;
        }
        li {
          -ms-flex: 0 0 33.3333%;
          flex: 0 0 33.3333%;
          max-width: 33.3333%;
          padding: {
            right: 10px;
            left: 10px;
          }
          figure {
            margin-bottom: 0;
          }
        }
      }
    }
    .features-list {
      padding-left: 0;
      list-style-type: none;
      margin: {
        top: 25px;
        bottom: 30px;
      }
      li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 25px;
        color: $paragraph-color;

        svg {
          color: $light-green-color;
          display: inline-block;
          width: 20px;
          position: absolute;
          left: 0;
          top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .article-footer {
    margin: {
      top: 30px;
    }
    .article-tags {
      a {
        display: inline-block;
        color: $white-color;
        background-color: $purple-color;
        padding: 4px 12px;
        border-radius: 5px;
        margin-right: 3px;
        font-size: 14px;

        &:hover {
          color: $white-color;
          background-color: $light-green-color;
        }
      }
    }
  }
}
.comments-area {
  margin-top: 25px;

  .comments-title {
    margin: {
      bottom: 30px;
    }
    line-height: initial;
    font: {
      size: 20px;
    }
  }
  ol,
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .comment-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .children {
    margin-left: 20px;
  }
  .comment-body {
    border-bottom: 1px solid #eeeeee;
    padding-left: 65px;
    color: $black-color;
    font-size: 14px;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .reply {
      margin-top: 15px;

      a {
        border: 1px solid #ded9d9;
        color: $black-color;
        display: inline-block;
        padding: 6px 20px;
        border-radius: 30px;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        font: {
          size: 13px;
          weight: 500;
        }
        &:hover {
          color: $white-color;
          background-color: $light-green-color;
          border-color: $light-green-color;
        }
      }
    }
  }
  .comment-author {
    font-size: 16px;
    margin-bottom: 0.2em;
    position: relative;
    z-index: 2;

    .avatar {
      height: 50px;
      left: -65px;
      position: absolute;
      width: 50px;
    }
    .fn {
      font: {
        weight: 500;
      }
    }
    .says {
      display: none;
    }
  }
  .comment-metadata {
    margin-bottom: 0.8em;
    color: $paragraph-color;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font: {
      size: 13px;
      weight: 400;
    }
    a {
      color: $paragraph-color;

      &:hover {
        color: $light-green-color;
      }
    }
  }
  .comment-respond {
    margin-top: 30px;

    .comment-reply-title {
      margin-bottom: 0;
      font: {
        size: 20px;
      }
      #cancel-comment-reply-link {
        display: inline-block;
      }
    }
    .comment-form {
      overflow: hidden;
    }
    .comment-notes {
      margin: {
        bottom: 0;
        top: 10px;
      }
      .required {
        color: red;
      }
    }
    .comment-form-comment {
      margin-top: 15px;
      float: left;
      width: 100%;
    }
    label {
      display: block;
      color: $black-color;
      margin-bottom: 5px;
      font-size: $font-size;
    }
    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="week"],
    input[type="month"],
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="number"],
    textarea {
      display: block;
      width: 100%;
      background-color: $white-color;
      border: 1px solid #eeeeee;
      padding: 0.625em 0.7375em;
      outline: 0;
      transition: $transition;

      &:focus {
        border-color: $light-green-color;
      }
    }
    .comment-form-author {
      float: left;
      width: 50%;
      padding-right: 10px;
      margin-bottom: 20px;
    }
    .comment-form-email {
      float: left;
      width: 50%;
      padding-left: 12px;
      margin-bottom: 20px;
    }
    .comment-form-url {
      float: left;
      width: 100%;
      margin-bottom: 20px;
    }
    .comment-form-cookies-consent {
      width: 100%;
      float: left;
      position: relative;
      padding-left: 20px;
      margin-bottom: 20px;

      input {
        position: absolute;
        left: 0;
        top: 3px;
      }
      label {
        display: inline-block;
        margin: 0;
        color: $paragraph-color;
        font-weight: normal;
        position: relative;
        top: -2px;
      }
    }
    .form-submit {
      float: left;
      width: 100%;

      input {
        background: $light-green-color;
        border: none;
        color: $white-color;
        padding: 11px 25px 10px;
        display: inline-block;
        cursor: pointer;
        outline: 0;
        transition: $transition;
        border-radius: 5px;
        text-transform: uppercase;
        font: {
          weight: 600;
          size: $font-size;
        }
        &:hover,
        &:focus {
          color: $white-color;
          background-color: $purple-color;
          transform: translateY(-5px);
        }
      }
    }
  }
}
.startp-post-navigation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: {
    top: 30px;
  }
  padding: {
    top: 30px;
    bottom: 30px;
  }
  border: {
    top: 1px solid #eeeeee;
    bottom: 1px solid #eeeeee;
  }
}
.prev-link-wrapper {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: {
    right: 15px;
  }
  a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:hover {
      .image-prev {
        &::after {
          opacity: 1;
          visibility: visible;
        }
        .post-nav-title {
          opacity: 1;
          visibility: visible;
        }
      }
      .prev-link-info-wrapper {
        color: $light-green-color;
      }
    }
  }
  .image-prev {
    display: inline-block;
    min-width: 100px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    vertical-align: top;
    margin-right: 20px;
    position: relative;
    transition: $transition;

    img {
      border-radius: 5px;
    }
    &::after {
      display: block;
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5px;
      opacity: 0;
      background-color: $light-green-color;
      visibility: hidden;
      transition: $transition;
    }
    .post-nav-title {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      z-index: 2;
      color: $white-color;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      font: {
        size: 16px;
        weight: 500;
      }
    }
  }
  .prev-link-info-wrapper {
    color: $black-color;
    transition: $transition;
  }
  .prev-title {
    display: inline-block;
    font: {
      weight: 500;
      size: 17px;
    }
  }
  .meta-wrapper {
    display: block;
    color: $paragraph-color;
    text-transform: capitalize;
    margin-top: 5px;
    font: {
      weight: 400;
      size: 14px;
    }
  }
}
.next-link-wrapper {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
  padding: {
    left: 15px;
  }
  a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:hover {
      .image-next {
        &::after {
          opacity: 1;
          visibility: visible;
        }
        .post-nav-title {
          opacity: 1;
          visibility: visible;
        }
      }
      .next-link-info-wrapper {
        color: $light-green-color;
      }
    }
  }
  .image-next {
    display: inline-block;
    min-width: 100px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    vertical-align: top;
    margin-left: 20px;
    position: relative;
    transition: $transition;

    img {
      border-radius: 5px;
    }
    &::after {
      display: block;
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5px;
      opacity: 0;
      background-color: $light-green-color;
      visibility: hidden;
      transition: $transition;
    }
    .post-nav-title {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      z-index: 2;
      color: $white-color;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      font: {
        size: 16px;
        weight: 500;
      }
    }
  }
  .next-link-info-wrapper {
    transition: $transition;
    color: $black-color;
  }
  .next-title {
    display: inline-block;
    font: {
      weight: 500;
      size: 17px;
    }
  }
  .meta-wrapper {
    display: block;
    color: $paragraph-color;
    text-transform: capitalize;
    margin-top: 5px;
    font: {
      weight: 400;
      size: 14px;
    }
  }
}
blockquote,
.blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  z-index: 1;
  margin: {
    bottom: 20px;
    top: 20px;
  }
  p {
    color: $black-color;
    line-height: 1.6;
    margin-bottom: 0;
    font: {
      style: italic;
      weight: 500;
      size: 20px !important;
    }
  }
  cite {
    display: none;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: $light-green-color;
    margin: {
      top: 20px;
      bottom: 20px;
    }
  }
}

/*================================================
Sidebar Widget CSS
=================================================*/
.widget-area {
  .widget {
    margin-top: 35px;

    &:first-child {
      margin-top: 0;
    }
    .widget-title {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
      margin-bottom: 25px;
      position: relative;
      font-weight: 600;
      font: {
        size: 19px;
      }
      &::before {
        content: "";
        position: absolute;
        background: $light-green-color;
        bottom: -1px;
        left: 0;
        width: 50px;
        height: 1px;
      }
    }
  }
  .widget_search {
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
    background-color: $white-color;
    padding: 15px;

    form {
      position: relative;

      label {
        display: block;
        margin-bottom: 0;
      }
      .screen-reader-text {
        display: none;
      }
      .search-field {
        background-color: transparent;
        height: 50px;
        padding: 6px 15px;
        border: 1px solid #eeeeee;
        width: 100%;
        display: block;
        outline: 0;
        transition: $transition;

        &:focus {
          border-color: $light-green-color;
        }
      }
      button {
        position: absolute;
        right: 0;
        outline: 0;
        bottom: 0;
        height: 50px;
        width: 50px;
        z-index: 1;
        border: none;
        cursor: pointer;
        color: $white-color;
        background-color: $light-green-color;
        transition: $transition;
        font-size: 20px;

        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          left: 0;
          width: 20px;
          margin: {
            left: auto;
            right: auto;
          }
        }
        &:hover {
          background-color: $purple-color;
          color: $white-color;
        }
      }
    }
  }
  .widget_startp_posts_thumb {
    position: relative;
    overflow: hidden;

    .item {
      overflow: hidden;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
      .thumb {
        float: left;
        height: 80px;
        overflow: hidden;
        position: relative;
        width: 80px;
        margin-right: 15px;

        .fullimage {
          width: 80px;
          height: 80px;
          display: inline-block;
          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }
        }
      }
      .info {
        overflow: hidden;
        margin-top: 5px;

        time {
          display: block;
          color: $paragraph-color;
          text-transform: uppercase;
          margin: {
            top: 3px;
            bottom: 5px;
          }
          font: {
            size: 12px;
          }
        }
        .title {
          margin-bottom: 0;
          line-height: 1.5;
          font: {
            size: 15px;
            weight: 500;
          }
          a {
            display: inline-block;
          }
        }
      }
    }
  }
  .widget_recent_entries {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        color: $black-color;
        padding-left: 14px;
        line-height: 1.5;
        font: {
          weight: 500;
          size: $font-size;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $light-green-color;
          position: absolute;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 8px;
        }
        a {
          display: inline-block;
          color: $black-color;

          &:hover {
            color: $light-green-color;
          }
        }
        .post-date {
          display: block;
          font-size: 13px;
          color: $paragraph-color;
          margin-top: 4px;
        }
      }
    }
  }
  .widget_recent_comments {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        color: $paragraph-color;
        padding-left: 14px;
        line-height: 1.5;
        font: {
          weight: 500;
          size: $font-size;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $light-green-color;
          position: absolute;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 8px;
        }
        a {
          display: inline-block;
          color: $black-color;

          &:hover {
            color: $light-green-color;
          }
        }
      }
    }
  }
  .widget_archive {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        padding-left: 14px;
        color: $black-color;
        font: {
          weight: 500;
          size: $font-size;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $light-green-color;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 8px;
          position: absolute;
        }
        a {
          display: inline-block;
          color: $black-color;

          &:hover {
            color: $light-green-color;
          }
        }
      }
    }
  }
  .widget_categories {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        color: $paragraph-color;
        padding-left: 14px;
        font: {
          weight: 500;
          size: $font-size;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $light-green-color;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 6px;
          position: absolute;
        }
        a {
          color: $black-color;
          display: block;

          &:hover {
            color: $light-green-color;
          }
        }
        .post-count {
          float: right;
        }
      }
    }
  }
  .widget_meta {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        color: $black-color;
        padding-left: 14px;
        font: {
          weight: 500;
          size: $font-size;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $light-green-color;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 8px;
          position: absolute;
        }
        a {
          display: inline-block;
          color: $black-color;

          &:hover {
            color: $light-green-color;
          }
        }
      }
    }
  }
  .tagcloud {
    a {
      display: inline-block;
      color: $black-color;
      font: {
        weight: 500;
        size: 14px !important;
      }
      padding: 6px 13px;
      border: 1px dashed #eeeeee;
      margin: {
        top: 8px;
        right: 8px;
      }
      &:hover,
      &:focus {
        color: $white-color;
        background-color: $light-green-color;
        border-color: $light-green-color;
      }
    }
  }
}

/*================================================
Project Details CSS
=================================================*/
.project-details-image {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $black-color;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
  }
  a {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    color: $white-color;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    margin-top: 25px;

    &:hover {
      color: $light-green-color;
    }
  }
}
.project-details-desc {
  h3 {
    font: {
      size: 22px;
    }
    margin: {
      bottom: 15px;
      top: 15px;
    }
  }
  .project-details-information {
    overflow: hidden;

    .single-info-box {
      float: left;
      width: 20%;
      margin-top: 30px;

      h4 {
        font: {
          size: 18px;
        }
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 0;
      }
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          display: inline-block;

          a {
            margin-right: 10px;
            color: $paragraph-color;

            svg {
              width: 18px;
            }
            &:hover {
              color: $light-green-color;
              transform: translateY(-5px);
            }
          }
        }
      }
    }
  }
}

/*================================================
Contact CSS
=================================================*/
.contact-area {
  .form-check {
    margin-bottom: 20px;
    label {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      a {
        color: $light-green-color;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.contact-info-box {
  text-align: center;
  transition: 0.4s;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: $white-color;
  padding: 30px;

  .icon {
    display: inline-block;
    width: 65px;
    height: 65px;
    line-height: 62px;
    background: $bg-color;
    border-radius: 50%;
    font-size: 30px;
    color: $light-green-color;
    transition: 0.4s;
  }
  h3 {
    font: {
      size: 20px;
    }
    margin: 25px 0 15px;
  }
  p {
    margin-bottom: 0;

    a {
      color: $paragraph-color;

      &:hover {
        color: $light-green-color;
      }
    }
  }
  &:hover {
    transform: translateY(-10px);

    .icon {
      color: $white-color;
      background: $light-green-color;
    }
  }
}
#map {
  height: 500px;
  width: 100%;
}
.contact-form {
  .invalid-feedback {
    margin-top: 10px;
  }
  .btn {
    margin-top: 10px;
  }
  .form-check {
    margin-bottom: 10px;
    label {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      a {
        color: $light-green-color;
      }
    }
  }
}

/*================================================
Coming Soon CSS
=================================================*/
.coming-soon-area {
  position: relative;
  z-index: 1;
  height: 100vh;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &::before {
    z-index: -1;
    background: linear-gradient(-9deg, #6a82fb 0%, #6a82fb 40%, #fc5c7d 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.8;
  }
  .social-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    bottom: 30px;

    li {
      display: inline-block;

      &.list-heading {
        display: block;
        color: $white-color;
        margin-bottom: 10px;
        font: {
          size: 16px;
          weight: 500;
        }
      }
      a {
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: $light-green-color;
        color: $white-color;
        border-radius: 50%;
        margin: 0 2px;

        svg {
          width: 15px;
        }
        &.facebook {
          background-color: #3b5998;
        }
        &.twitter {
          background-color: #1da1f2;
        }
        &.linkedin {
          background-color: #0077b5;
        }
        &.instagram {
          background-color: #c13584;
        }
        &:hover,
        &:focus {
          background-color: $light-green-color;
        }
      }
    }
  }
}
.coming-soon-content {
  text-align: center;
  max-width: 820px;
  margin: -80px auto 0;

  h1 {
    color: $white-color;
    font: {
      size: 55px;
      weight: 600;
    }
    margin-bottom: 15px;
  }
  p {
    color: $white-color;
    font-size: 16px;
    margin: 0 auto;
    max-width: 620px;
  }
  form {
    position: relative;
    margin: 35px auto 55px;
    max-width: 520px;

    .email-input {
      display: block;
      width: 100%;
      height: 56px;
      border: none;
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1),
        0px 0px 0px 5px rgba(255, 255, 255, 0.3);
      border-radius: 40px;
      padding: 15px 25px;
      font-size: 16px;
      outline: 0 !important;
      background: #f1f2f3;
    }
    .submit-btn {
      position: absolute;
      right: 3px;
      top: 3px;
      height: 50px;
      background: $light-green-color;
      color: $white-color;
      border: none;
      border-radius: 40px;
      width: 130px;
      outline: 0 !important;
      cursor: pointer;
      text-transform: uppercase;
      transition: $transition;
      font: {
        size: 15px;
        weight: 500;
      }
      &:hover,
      &:focus {
        background-color: $purple-color;
      }
    }
  }
  #timer {
    div {
      background: $white-color;
      display: inline-block;
      border-radius: 50%;
      width: 110px;
      height: 110px;
      color: $purple-color;
      box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
      margin: 0 10px;
      padding-top: 18px;
      font: {
        size: 35px;
        weight: 500;
      }
      span {
        display: block;
        margin-top: -4px;
        color: $paragraph-color;
        font-size: 15px;
      }
    }
  }
}

/*================================================
404 Error CSS
=================================================*/
.error-area {
  height: 100vh;
}
.error-content {
  text-align: center;
  margin-top: -70px;

  .notfound-404 {
    position: relative;
    height: 280px;
    z-index: -1;
    margin-bottom: 30px;

    h1 {
      font: {
        size: 230px;
        weight: 900;
      }
      margin: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background: url(/default_images/oops-bg.jpg) no-repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background: {
        size: cover;
        position: center;
      }
    }
  }
  h3 {
    font: {
      weight: 600;
      size: 25px;
    }
    margin-bottom: 15px;
  }
  p {
    max-width: 550px;
    margin: 0 auto 25px;
  }
}

/*================================================
CTA CSS
=================================================*/
.cta-area {
  background: $gradient-color;

  // .container {
  //     max-width: 945px;
  // }
}
.cta-content {
  h3 {
    color: $white-color;
    margin: 0;
    font: {
      size: 24px;
      weight: 600;
    }
  }
}
.cta-right-content {
  text-align: right;

  .hosting-price {
    display: inline-block;

    span {
      color: #f6fe6a;
      display: block;
    }
    h4 {
      margin: 10px 0 0;
      color: $white-color;
      font-size: 20px;
    }
  }
  .buy-btn {
    display: inline-block;
    padding-left: 35px;
    position: relative;
    top: -15px;

    .btn-primary {
      background: $purple-color;
      box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

      &::after,
      &::before {
        background: $white-color;
        box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
      }
      &:hover,
      &:focus {
        color: $light-green-color;
      }
    }
  }
}

/*================================================
Repair Main Banner CSS
=================================================*/
.repair-main-banner {
  overflow-x: hidden;
  padding: {
    top: 200px;
    bottom: 140px;
  }
}
.repair-banner-content {
  h1 {
    margin-bottom: 20px;
    font: {
      size: 45px;
      weight: 500;
    }
  }
  p {
    margin-bottom: 30px;
  }
}
.repair-banner-image {
  position: relative;
  text-align: center;

  img {
    &:nth-child(2) {
      position: absolute;
      left: 0;
      top: -65px;
      right: -30px;
      margin: 0 auto;
      z-index: -1;
    }
    &:nth-child(3) {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -69px;
      z-index: -2;
    }
    &:nth-child(4) {
      position: absolute;
      left: 0;
      right: 0;
      top: -30px;
      z-index: -1;
      margin: 0 auto;
    }
  }
}

/*================================================
Repair Partner CSS
=================================================*/
.repair-partner-area {
  padding: {
    top: 45px;
    bottom: 45px;
  }
}
.single-repair-partner {
  text-align: center;

  a {
    display: block;
    position: relative;

    img {
      width: auto !important;
      display: inline-block !important;

      &:last-child {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
      }
    }
    &:hover,
    &:focus {
      img {
        &:last-child {
          opacity: 1;
          visibility: visible;
          top: 0;
        }
      }
    }
  }
}

/*================================================
Repair About CSS
=================================================*/
.repair-about-content {
  .sub-title {
    text-transform: uppercase;
    color: $light-green-color;
    display: block;
    font: {
      size: 15px;
      weight: 400;
    }
  }
  h2 {
    font: {
      size: 24px;
      weight: 600;
    }
    margin: {
      top: 10px;
      bottom: 10px;
    }
  }
  ul {
    padding: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: {
      top: -10px;
      right: -10px;
      left: -10px;
    }
    li {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;
      margin-top: 25px;
      color: $paragraph-color;
      position: relative;
      padding: {
        right: 10px;
        left: 10px;
      }
      span {
        display: block;
        position: relative;
        z-index: 1;
        padding: 10px;
        border-radius: 3px;
        transition: $transition;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

        svg {
          margin-right: 5px;
          display: inline-block;
          color: $light-green-color;
          transition: $transition;
          width: 18px;
        }
        &::before {
          content: "";
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: $gradient-color;
          opacity: 0;
          visibility: hidden;
          position: absolute;
          border-radius: 3px;
          z-index: -1;
          transition: $transition;
        }
        &:hover,
        &:focus {
          color: $white-color;
          transform: translateY(-5px);

          &::before {
            opacity: 1;
            visibility: visible;
          }
          svg {
            color: $white-color;
          }
        }
      }
    }
  }
}
.repair-about-image {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;

    &:nth-child(1) {
      left: 0;
      top: 0;
      z-index: 1;
    }
    &:nth-child(2) {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      text-align: center;
      z-index: 2;
      margin: 0 auto;
      border: 5px solid #f8fbff;
      width: 350px;
    }
    &:nth-child(3) {
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    &:nth-child(4) {
      right: 0;
      top: 0;
    }
    &:nth-child(5) {
      left: 0;
      bottom: 0;
    }
  }
}

/*================================================
Repair Services CSS
=================================================*/
.repair-services-area {
  padding-bottom: 50px;
}
.single-repair-services {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding: 40px 30px;
  text-align: center;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &::before {
    position: absolute;
    transition: $transition;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white-color;
    z-index: -1;
    border: 1px dashed #cdf1d8;
  }
  &::after {
    position: absolute;
    transition: $transition;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gradient-color;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    border: 1px dashed #cdf1d8;
  }
  h3 {
    font-size: 19px;
    transition: $transition;
    line-height: 1.4;
    margin: {
      top: 22px;
      bottom: 12px;
    }
  }
  .icon {
    display: inline-block;
    color: $light-green-color;
    width: 85px;
    height: 85px;
    line-height: 85px;
    border: 1px solid #e3f7e9;
    border-radius: 50%;
    transition: $transition;

    i {
      &::before {
        font-size: 35px;
      }
    }
  }
  p {
    margin-bottom: 25px;
    transition: $transition;
  }
  a {
    width: 35px;
    background-color: #f9fafb;
    height: 35px;
    line-height: 35px;
    color: $black-color;
    border-radius: 50%;

    svg {
      width: 15px;
    }
  }
  &:hover,
  &:focus {
    &::before {
      opacity: 0;
      visibility: hidden;
    }
    &::after {
      opacity: 0.85;
      visibility: visible;
    }
    h3 {
      color: $white-color;
    }
    p {
      color: $white-color;
    }
    .icon {
      border-color: $white-color;
      color: $white-color;
    }
    a {
      background-color: $white-color;
      color: $light-green-color;
    }
  }
}

/*================================================
Repair CTA CSS
=================================================*/
.repair-cta-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: {
    top: 10px;
    bottom: 10px;
  }
}
.cta-repair-content {
  padding: 30px 0;
  h3 {
    color: $white-color;
    font-size: 25px;
    margin-bottom: 17px;
  }
  p {
    color: $white-color;
    opacity: 0.95;
    margin-bottom: 25px;
  }
}
.cta-repair-img {
  text-align: right;
}
.circle-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.cta-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

/*================================================
Repair Team CSS
=================================================*/
.repair-team-area {
  .container {
    max-width: 1175px;
  }
  .single-team {
    .team-content {
      padding: {
        left: 25px;
        right: 25px;
      }
    }
  }
}

/*================================================
Repair Why Choose Us CSS
=================================================*/
.repair-why-choose-us {
  position: relative;
  z-index: 1;
  background: $gradient-color;

  .section-title {
    h2 {
      color: $white-color;
    }
    .bar {
      &::before {
        background: $white-color;
      }
    }
    p {
      color: $white-color;
      opacity: 0.95;
    }
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 37%;
    width: 100%;
    background: $white-color;
    z-index: -1;
  }
}
.single-repair-box {
  text-align: center;
  background-color: $white-color;
  border: 1px dashed #cdf1d8;
  padding: 30px;
  position: relative;
  z-index: 1;
  transition: $transition;

  h3 {
    font-size: 18px;
    margin: {
      top: 22px;
      bottom: 17px;
    }
  }
  p {
    margin-bottom: 25px;
  }
  a {
    width: 40px;
    background-color: #eef0f3;
    height: 40px;
    line-height: 40px;
    color: $black-color;
    border-radius: 50%;

    svg {
      width: 18px;
    }
  }
  .icon {
    display: inline-block;
    border: 1px solid #6ee9fa;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    position: relative;
    color: $white-color;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      background: $gradient-color2;
      border-radius: 50%;
      margin: 6px;
      transition: $transition;
    }
    i {
      &::before {
        font-size: 35px;
      }
    }
  }
  .back-icon {
    position: absolute;
    top: 58%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #4457c3;
    opacity: 0.06;
    z-index: -1;

    i {
      &::before {
        font-size: 200px;
      }
    }
  }
  &:hover,
  &:focus {
    border-color: $light-green-color;

    a {
      background-color: $light-green-color;
      color: $white-color;
    }
  }
}

/*================================================
Repair Feedback CSS
=================================================*/
.single-repair-feedback {
  background: $white-color;
  border-radius: 5px;
  margin-bottom: 55px;
  position: relative;
  text-align: center;
  padding: {
    left: 40px;
    right: 40px;
    top: 40px;
    bottom: 40px;
  }
  .client-img {
    position: relative;
    text-align: left;
    margin-bottom: 40px;
    display: inline-block;
    padding: {
      left: 108px;
      top: 18px;
    }
    img {
      border-radius: 50%;
      border: 2px solid $light-green-color;
      padding: 3px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      width: 90px;
      height: 90px;
    }
    h3 {
      font-size: 18px;
      margin: 0;
    }
    span {
      display: block;
      color: $purple-color;
      margin: {
        top: 8px;
      }
    }
  }
  p {
    font-size: 15px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -25px;
    width: 50px;
    height: 50px;
    background: $white-color;
    right: 0;
    margin: 0 auto;
    transform: rotate(45deg);
  }
  &::after {
    content: "\f11b";
    color: #d9dcde;
    font: {
      family: Flaticon;
      size: 30px;
      style: normal;
      size: 65px;
    }
    position: absolute;
    top: 0;
    left: 25px;
  }
}

/*================================================
IoT Main Banner CSS
=================================================*/
.iot-main-banner {
  position: relative;
  z-index: 1;
  padding: {
    top: 215px;
    bottom: 150px;
  }
}
.iot-banner-content {
  max-width: 445px;

  span {
    color: $light-green-color;
    font-size: 15px;
    display: block;
  }
  h2 {
    font-size: 40px;
    margin: {
      top: 7px;
      bottom: 17px;
    }
    line-height: 1.3;
  }
  .btn {
    margin-top: 10px;
  }
}
.iot-banner-image {
  position: absolute;
  text-align: center;
  right: 30px;
  top: 56%;
  transform: translateY(-50%);

  img {
    &:first-child {
      max-width: 730px;
    }
    &:last-child {
      position: absolute;
      left: -118px;
      right: 0;
      margin: 0 auto;
      top: 39px;
      z-index: -1;
    }
  }
}
.animate-border {
  span {
    position: absolute;
    display: block;
    width: 5%;
    padding-bottom: 5%;
    top: 51%;
    left: 67%;
    opacity: 0;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
    pointer-events: none;
    border-radius: 50%;
    z-index: -2;

    &:nth-child(1) {
      animation: 4.2s ease-out 0.5s infinite normal none running pulsei;
    }
    &:nth-child(2) {
      animation: 4.2s ease-out 1.5s infinite normal none running pulsei;
    }
    &:nth-child(3) {
      animation: 4.2s ease-out 2.5s infinite normal none running pulsei;
    }
    &:nth-child(4) {
      animation: 4.2s ease-out 3.5s infinite normal none running pulsei;
    }
  }
}
@keyframes pulsei {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    border: 1.5px solid #b3eaf8;
    opacity: 1;
    width: 5%;
    padding-bottom: 5%;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 0;
    width: 50%;
    border: 1.5px solid #b3eaf8;
    padding-bottom: 50%;
  }
}

/*================================================
IoT Partner CSS
=================================================*/
.iot-partner-area {
  padding: {
    top: 45px;
    bottom: 45px;
  }
}
.single-iot-partner {
  text-align: center;

  a {
    display: block;
    position: relative;

    img {
      width: auto !important;
      display: inline-block !important;

      &:last-child {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
      }
    }
    &:hover,
    &:focus {
      img {
        &:last-child {
          opacity: 1;
          visibility: visible;
          top: 0;
        }
      }
    }
  }
}

/*================================================
Iot Services CSS
=================================================*/
.single-iot-services {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding: 40px 30px;
  text-align: center;
  background-color: $white-color;
  border: 1px dashed #cdf1d8;

  &::after {
    position: absolute;
    transition: $transition;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gradient-color;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }
  h3 {
    font-size: 18px;
    transition: $transition;
    margin: {
      top: 22px;
      bottom: 17px;
    }
    line-height: 1.5;
  }
  .icon {
    display: inline-block;
    color: $light-green-color;
    width: 85px;
    height: 85px;
    line-height: 85px;
    border: 1px solid #e3f7e9;
    border-radius: 50%;
    transition: $transition;

    i {
      &::before {
        font-size: 35px;
      }
    }
  }
  p {
    margin-bottom: 25px;
    transition: $transition;
  }
  a {
    width: 35px;
    background-color: #f9fafb;
    height: 35px;
    line-height: 35px;
    color: $black-color;
    border-radius: 50%;

    svg {
      width: 15px;
    }
  }
  &:hover,
  &:focus {
    &::after {
      opacity: 1;
      visibility: visible;
    }
    h3 {
      color: $white-color;
    }
    p {
      color: $white-color;
    }
    .icon {
      border-color: $white-color;
      color: $white-color;
    }
    a {
      background-color: $white-color;
      color: $light-green-color;
    }
  }
}

/*================================================
IoT CTA CSS
=================================================*/
.iot-cta-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: {
    top: 10px;
    bottom: 10px;
  }
}
.cta-iot-content {
  padding-top: 30px;
  padding-bottom: 30px;
  h3 {
    color: $white-color;
    font-size: 25px;
    margin-bottom: 17px;
  }
  p {
    color: $white-color;
    opacity: 0.95;
    margin-bottom: 25px;
  }
}
.cta-iot-img {
  text-align: center;
}

/*================================================
IoT Features CSS
=================================================*/
.iot-features-content {
  h3 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .btn {
    margin-top: 15px;
  }
}
.iot-features-image {
  position: relative;
  text-align: center;
}

/*================================================
IoT Team CSS
=================================================*/
.iot-team-area {
  .container {
    max-width: 1175px;
  }
  .single-team {
    .team-content {
      padding: {
        left: 25px;
        right: 25px;
      }
    }
  }
}

/*================================================
IoT Why Choose Us CSS
=================================================*/
.iot-why-choose-us {
  position: relative;
  z-index: 1;
  background: $gradient-color;

  .section-title {
    h2 {
      color: $white-color;
    }
    .bar {
      &::before {
        background: $white-color;
      }
    }
    p {
      color: $white-color;
      opacity: 0.95;
    }
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 37%;
    width: 100%;
    background: $white-color;
    z-index: -1;
  }
}
.single-iot-box {
  text-align: center;
  background-color: $white-color;
  border: 1px dashed #cdf1d8;
  padding: 30px;
  position: relative;
  z-index: 1;
  transition: $transition;

  h3 {
    font-size: 20px;
    margin: {
      top: 22px;
      bottom: 17px;
    }
  }
  p {
    margin-bottom: 25px;
  }
  a {
    width: 40px;
    background-color: #eef0f3;
    height: 40px;
    line-height: 40px;
    color: $black-color;
    border-radius: 50%;

    svg {
      width: 18px;
    }
  }
  .icon {
    display: inline-block;
    border: 1px solid #f3f8fd;
    width: 100px;
    height: 100px;
    line-height: 96px;
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      background: #f7fafd;
      border-radius: 50%;
      margin: 6px;
      transition: $transition;
    }
    i {
      &::before {
        font-size: 35px;
      }
    }
    img {
      width: 45px;
    }
  }
  &:hover,
  &:focus {
    border-color: $light-green-color;

    a {
      background-color: $light-green-color;
      color: $white-color;
    }
  }
}

/*================================================
ML Main Banner CSS
=================================================*/
.ml-main-banner {
  position: relative;
  z-index: 1;
  padding: {
    top: 130px;
    bottom: 140px;
  }
  background: {
    image: url(/default_images/banner-bg.jpg);
    position: bottom right;
    size: cover;
    repeat: no-repeat;
  }
  .container-fluid {
    padding: {
      left: 70px;
      right: 70px;
    }
  }
}
.ml-banner-content {
  h1 {
    margin-bottom: 15px;
    line-height: 1.3;
    font: {
      size: 40px;
      weight: 500;
    }
  }
  p {
    font-size: 15px;
    max-width: 490px;
  }
  .btn {
    margin-top: 5px;
  }
}
.ml-banner-single-image {
  text-align: center;
}
.ml-main-section {
  position: relative;
  z-index: 1;
  padding: {
    top: 260px;
    bottom: 240px;
  }
  background: {
    image: url(/default_images/banner-bg.jpg);
    position: bottom right;
    size: cover;
    repeat: no-repeat;
  }
  .ml-banner-content {
    h1 {
      font-size: 37px;
    }
    p {
      max-width: 100%;
    }
  }
}
.ml-banner-image {
  position: relative;
  width: 100%;
  height: 100%;
  top: 140px;

  img {
    position: absolute;

    &:nth-child(1) {
      left: 195px;
      top: -125px;
      z-index: 2;
    }
    &:nth-child(2) {
      top: -83px;
      left: 153px;
      z-index: 1;
    }
    &:nth-child(3) {
      top: 40px;
      left: 90px;
    }
    &:nth-child(4) {
      top: 0;
      right: 165px;
      z-index: 3;
    }
    &:nth-child(5) {
      top: -260px;
      left: 110px;
    }
    &:nth-child(6) {
      right: 130px;
      top: -240px;
    }
    &:nth-child(7) {
      bottom: 0;
      right: 0;
      z-index: 2;
    }
    &:nth-child(8) {
      right: 10px;
      bottom: 205px;
      z-index: 1;
    }
    &:nth-child(9) {
      left: 15px;
      top: -185px;
      z-index: 1;
    }
    &:nth-child(10) {
      left: 1px;
      bottom: -2px;
      z-index: 2;
    }
    &:nth-child(11) {
      left: 0;
      top: -20px;
    }
    &:nth-child(12) {
      display: none;
    }
  }
}

/*================================================
ML Partner CSS
=================================================*/
.ml-partner-area {
  &.mt-minus-top {
    margin-top: -80px;
  }
}
.single-ml-partner {
  text-align: center;

  a {
    display: block;
    position: relative;

    img {
      width: auto !important;
      display: inline-block !important;

      &:last-child {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
      }
    }
    &:hover,
    &:focus {
      img {
        &:last-child {
          opacity: 1;
          visibility: visible;
          top: 0;
        }
      }
    }
  }
}

/*================================================
Our Solutions CSS
=================================================*/
.solutions-area {
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
}
.single-solutions-box {
  padding: 20px 15px;
  text-align: center;
  transition: $transition;
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  border: 1px solid #c5fffd;
  border-radius: 5px;

  .icon {
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
    display: inline-block;

    img {
      transition: $transition;
    }
    &::before {
      content: "";
      position: absolute;
      left: -5px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      background: #f0fdfe;
      box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.09);
      border-radius: 30% 70% 70% 30% / 40% 60% 40% 60%;
      width: 60px;
      height: 60px;
    }
  }
  h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background: linear-gradient(
      to right top,
      #d5fefd,
      #e0fdff,
      #effbff,
      #fbfaff,
      #fffcff
    );
  }
  .learn-more-btn {
    color: #365267;
    font: {
      size: $font-size;
      weight: 600;
    }
    svg {
      width: 18px;
      position: relative;
      top: -1px;
      margin-right: 3px;
    }
    &:hover {
      color: $light-green-color;
    }
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .icon {
      img {
        transform: rotateY(-360deg);
      }
    }
  }
}

/*================================================
ML About CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
}
.ml-about-img {
  text-align: center;
}
.ml-about-content {
  max-width: 540px;

  .sub-title {
    display: inline-block;
    color: $purple-color;
    background-color: #f5dffd;
    margin-bottom: 18px;
    border-radius: 30px;
    padding: 5px 20px 4px;
    font-size: 13px;
  }
  h2 {
    margin-bottom: 0;
    line-height: 1.4;
    font: {
      size: 28px;
      weight: 600;
    }
    text-transform: capitalize;
  }
  .bar {
    height: 5px;
    width: 90px;
    background: #cdf1d8;
    margin: 20px 0;
    position: relative;
    border-radius: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -2.7px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: $light-green-color;
      animation: {
        duration: 3s;
        timing-function: linear;
        iteration-count: infinite;
        name: MOVE-BG;
      }
    }
  }
  p {
    margin-bottom: 12px;
  }
  .btn {
    margin-top: 10px;
  }
}

/*================================================
ML Services CSS
=================================================*/
.ml-services-area {
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  background: linear-gradient(
    to left bottom,
    #fffcff,
    #fbfaff,
    #effbff,
    #e0fdff,
    #d5fefd
  );
}
.single-ml-services-box {
  position: relative;
  z-index: 1;
  background-color: $white-color;
  text-align: center;
  margin-bottom: 30px;
  padding: 30px 25px;
  overflow: hidden;
  transition: $transition;

  .image {
    margin-bottom: 25px;
  }
  h3 {
    font-size: 20px;
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 0;
  }
  &::before {
    content: "";
    position: absolute;
    left: -15px;
    bottom: -15px;
    width: 30px;
    height: 30px;
    transition: $transition;
    transform: rotate(45deg);
    background: $light-green-color;
    visibility: hidden;
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
}

/*================================================
ML Feedback CSS
=================================================*/
.ml-feedback-area {
  background: linear-gradient(
    to right top,
    #d5fefd,
    #e0fdff,
    #effbff,
    #fbfaff,
    #fffcff
  );
  position: relative;
  z-index: 1;
}
.single-ml-feedback-item {
  box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
  margin-bottom: 45px;
  background-color: $white-color;
  position: relative;
  padding: 60px 70px 60px 190px;
  border-radius: 5px;

  .client-info {
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    img {
      border-radius: 5px;
      border: 2px solid #f4f5fe;
      width: 80px !important;
      height: 80px !important;
      padding: 5px;
      display: inline-block !important;
    }
    h3 {
      margin: {
        bottom: 0;
        top: 10px;
      }
      font: {
        size: 17px;
      }
    }
    span {
      display: block;
      color: $purple-color;
      margin-top: 5px;
      font-size: 13px;
    }
  }
  p {
    margin-bottom: 0;
    color: #4a6f8a;
  }
  .rating {
    margin-top: 10px;

    svg {
      color: #ff612f;
      width: 18px;
      margin-right: 2px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 5px;
    bottom: -15px;
    background-color: $white-color;
    box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
    z-index: -1;
    margin: {
      left: 30px;
      right: 30px;
    }
  }
}
.ml-feedback-slides {
  max-width: 750px;
  margin: -40px auto 0;

  .single-ml-feedback-item {
    margin-top: 40px;
  }
}

/*================================================
Free Trial CSS
=================================================*/
.free-trial-area {
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: -30%;
    top: 0;
    background: linear-gradient(
      to right,
      #d5fefd,
      #e1fdff,
      #effdff,
      #fafdff,
      #ffffff
    );
  }
  .shape6 {
    top: 75%;
  }
}
.free-trial-image {
  text-align: center;
}
.free-trial-content {
  max-width: 540px;

  h2 {
    margin-bottom: 0;
    font: {
      size: 28px;
      weight: 600;
    }
  }
  form {
    position: relative;
    margin: {
      top: 25px;
      bottom: 10px;
    }
    .input-newsletter {
      display: block;
      width: 99%;
      height: 50px;
      color: $white-color;
      border: none;
      outline: 0;
      background-color: $black-color;
      border-radius: 30px;
      padding: {
        top: 3px;
        left: 20px;
      }
      &::placeholder {
        color: #d5d5d5;
        transition: $transition;
      }
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 50px;
      cursor: pointer;
      border: none;
      padding: 0 30px;
      line-height: 50px;
      background-color: $light-green-color;
      border-radius: 0 30px 30px 0;
      transition: $transition;
      color: $white-color;
      font: {
        size: 16px;
      }
      &:hover {
        background-color: $purple-color;
        color: $white-color;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
}

/*================================================
Newsletter CSS
=================================================*/
.newsletter-area {
  position: relative;
  z-index: 1;

  .container-fluid {
    padding: {
      left: 0;
      right: 0;
    }
    .row {
      margin: {
        left: 0;
        right: 0;
      }
      .col-lg-6 {
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    width: 65%;
    height: 100%;
    bottom: 0;
    z-index: 1;
    -webkit-clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
    background: linear-gradient(135deg, #43e794 0%, #23bdb8 100%);
  }
}
.newsletter-image {
  img {
    width: 100%;
  }
}
.newsletter-content {
  position: relative;
  z-index: 2;
  max-width: 540px;

  h2 {
    color: $white-color;
    margin-bottom: 0;
    line-height: 1.5;
    font: {
      size: 28px;
      weight: 600;
    }
  }
  .newsletter-form {
    position: relative;
    padding-right: 160px;
    margin: {
      bottom: 15px;
      top: 20px;
    }
    .input-newsletter {
      display: block;
      width: 100%;
      height: 50px;
      color: $white-color;
      border: none;
      outline: 0;
      background-color: #333333;
      border-radius: 30px;
      padding: {
        left: 20px;
        top: 2px;
      }
      &::placeholder {
        transition: $transition;
        color: $white-color;
      }
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 50px;
      border: none;
      padding: 0 30px;
      line-height: 50px;
      background-color: $white-color;
      border-radius: 30px;
      transition: $transition;
      color: $black-color;
      font: {
        size: 14px;
        weight: 600;
      }
      &:hover {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }
  p {
    color: $white-color;
    max-width: 400px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: $gradient-color;
    display: none;
  }
}

/*================================================
ML Projects CSS
=================================================*/
.ml-projects-area {
  position: relative;
  overflow: hidden;
  z-index: 1;

  .section-title {
    text-align: left;
    max-width: 530px;

    h2 {
      max-width: 470px;
      line-height: 1.3;
    }
    .bar {
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
  .container-fluid {
    position: relative;
  }
}
.single-ml-projects-box {
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175);
  background-color: $white-color;

  img {
    border-radius: 5px;
  }
  .plus-icon {
    a {
      background-color: $light-green-color;
      width: 70px;
      height: 70px;
      position: absolute;
      top: 20px;
      right: 20px;
      transform: scale(0);
      transition: all 0.4s ease-out 0s;
      overflow: hidden;
      border-radius: 50%;

      span {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 2px;
          height: 100%;
          background-color: $white-color;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &::after {
          height: 2px;
          width: 100%;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      &:hover {
        background-color: $purple-color;
      }
    }
  }
  &:hover {
    .plus-icon {
      a {
        transform: scale(1);
      }
    }
  }
}

/*================================================
Agency Main Banner CSS
=================================================*/
.agency-main-banner {
  position: relative;
  z-index: 1;

  .container-fluid {
    padding: {
      left: 0;
      right: 0;
    }
    .row {
      margin: {
        left: 0;
        right: 0;
      }
      .col-lg-6 {
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }
  .shape2 {
    left: 5%;
  }
  .shape8 {
    right: 50%;
  }
  .shape4 {
    right: 50%;
  }
}
.agency-banner-content {
  margin-left: auto;
  max-width: 650px;

  .sub-title {
    text-transform: uppercase;
    display: block;
    font-size: 16px;
    color: $paragraph-color;
    margin-bottom: 8px;
  }
  h1 {
    margin-bottom: 15px;
    line-height: 1.3;
    font: {
      size: 50px;
      weight: 500;
    }
  }
  p {
    font-size: 15px;
    max-width: 450px;
  }
  .btn {
    margin-top: 5px;
  }
}
.agency-banner-image {
  text-align: right;
}

/*================================================
Our Solutions CSS
=================================================*/
.solutions-area {
  .shape2 {
    left: 5%;
  }
}
.solutions-box {
  transition: $transition;
  position: relative;
  margin-bottom: 30px;
  z-index: 1;

  .icon {
    color: $light-green-color;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    padding-left: 20px;

    i {
      font-size: 45px;
    }
    img {
      position: absolute;
      left: 0;
      z-index: -1;
      top: -25px;
    }
  }
  h3 {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.3;
  }
  .learn-more-btn {
    color: #365267;
    font: {
      size: $font-size;
      weight: 600;
    }
    svg {
      width: 18px;
      position: relative;
      top: -1px;
      margin-right: 3px;
    }
    &:hover {
      color: $light-green-color;
    }
  }
}

/*================================================
Agency About CSS
=================================================*/
.agency-about-area {
  position: relative;
  z-index: 1;

  .container-fluid {
    padding: {
      left: 0;
      right: 0;
    }
    .row {
      margin: {
        left: 0;
        right: 0;
      }
      .col-lg-6 {
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }
}
.agency-about-img {
  padding-right: 100px;

  img {
    width: 100%;
  }
}
.agency-about-content {
  max-width: 540px;

  .sub-title {
    display: inline-block;
    color: $purple-color;
    background-color: #f5dffd;
    margin-bottom: 18px;
    border-radius: 30px;
    padding: 5px 20px 4px;
    font-size: 13px;
  }
  h2 {
    margin-bottom: 0;
    line-height: 1.4;
    font: {
      size: 28px;
      weight: 600;
    }
    text-transform: capitalize;
  }
  .bar {
    height: 5px;
    width: 90px;
    background: #cdf1d8;
    margin: 20px 0;
    position: relative;
    border-radius: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -2.7px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: $light-green-color;
      animation: {
        duration: 3s;
        timing-function: linear;
        iteration-count: infinite;
        name: MOVE-BG;
      }
    }
  }
  p {
    margin-bottom: 12px;
  }
  .btn {
    margin-top: 10px;
  }
}
.shape10 {
  position: absolute;
  right: 0;
  top: -40%;
  z-index: -1;
}

/*================================================
Agency Services CSS
=================================================*/
.agency-services-area {
  position: relative;
  z-index: 1;
  background: linear-gradient(
    to top,
    #d5fefd,
    #e1fdff,
    #effdff,
    #fafdff,
    #ffffff
  );
}
.agency-services-box {
  margin-bottom: 30px;
  text-align: center;

  img {
    border-radius: 7px;
  }
  .content {
    transition: $transition;
    background: $white-color;
    position: relative;
    border-radius: 7px;
    padding: 25px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.05);
    margin: {
      left: 15px;
      right: 15px;
      top: -30px;
    }
    h3 {
      transition: $transition;
      font-size: 20px;
      margin-bottom: 0;
    }
    .read-more-btn {
      display: inline-block;
      border-radius: 30px;
      padding: 9px 25px;
      border: 1px solid #e6e6e6;
      background-color: $white-color;
      position: absolute;
      transition: $transition;
      left: 50%;
      transform: translateX(-50%);
      bottom: -22px;
      opacity: 0;
      visibility: hidden;
      font: {
        size: 14px;
        weight: 500;
      }
      svg {
        width: 15px;
        margin-left: 2px;
      }
      &:hover {
        background-color: $light-green-color;
        border-color: $light-green-color;
        color: $white-color;
      }
    }
  }
  &:hover {
    .content {
      transform: translateY(-20px);

      h3 {
        transform: translateY(-10px);
      }
      .read-more-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/*================================================
Testimonials CSS
=================================================*/
.single-testimonials-item {
  box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
  margin-bottom: 45px;
  background-color: $white-color;
  position: relative;
  padding: 110px 65px 60px;
  border-radius: 5px;
  text-align: center;

  p {
    margin-bottom: 0;
    color: #4a6f8a;
    font-size: 15px;
  }
  .client-info {
    text-align: left;
    position: relative;
    padding-left: 95px;
    margin-top: 25px;
    max-width: 235px;
    margin: {
      left: auto;
      right: auto;
    }
    img {
      border-radius: 5px;
      border: 2px solid #f4f5fe;
      width: 80px !important;
      height: 80px !important;
      padding: 5px;
      display: inline-block !important;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .rating {
      svg {
        color: #ff612f;
        width: 16px;
        margin-right: 3px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    h3 {
      margin: {
        bottom: 0;
        top: 8px;
      }
      font: {
        size: 17px;
      }
    }
    span {
      display: block;
      color: $purple-color;
      margin-top: 5px;
      font-size: 13px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 5px;
    bottom: -15px;
    background-color: $white-color;
    box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
    z-index: -1;
    margin: {
      left: 30px;
      right: 30px;
    }
  }
  &::before {
    content: "\f11b";
    color: #d9dcde;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    text-align: center;
    font: {
      family: Flaticon;
      style: normal;
      size: 65px;
    }
  }
}
.ml-feedback-slides {
  .single-testimonials-item {
    margin-top: 40px;
  }
}
.user-shape1 {
  position: absolute;
  z-index: -1;
  top: 12%;
  left: 10%;
  animation: movebounce 9s linear infinite;
}
.user-shape2 {
  position: absolute;
  z-index: -1;
  left: 7%;
  animation: moveleftbounce 7s linear infinite;
  top: 47%;
}
.user-shape3 {
  position: absolute;
  z-index: -1;
  bottom: 12%;
  left: 15%;
  animation: movebounce 5s linear infinite;
}
.user-shape4 {
  position: absolute;
  z-index: -1;
  top: 12%;
  right: 10%;
  animation: movebounce 9s linear infinite;
}
.user-shape5 {
  position: absolute;
  z-index: -1;
  right: 7%;
  top: 45%;
  animation: moveleftbounce 7s linear infinite;
}
.user-shape6 {
  position: absolute;
  z-index: -1;
  bottom: 12%;
  right: 15%;
  animation: movebounce 5s linear infinite;
}
.shape9 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}

/*================================================
Agency CTA CSS
=================================================*/
.agency-cta-area {
  position: relative;
  z-index: 1;
  background: {
    image: url(/default_images/agency-gradient-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
}
.agency-cta-content {
  text-align: center;

  h2 {
    color: $white-color;
    margin-bottom: 30px;
    font: {
      size: 28px;
      weight: 600;
    }
  }
}

/*btn-gradient*/
.btn-gradient {
  color: $white-color;
  background: linear-gradient(90deg, #ee0979 0, #ff6a00 100%);
  box-shadow: unset !important;
  transform: unset !important;

  &::after {
    display: none;
  }
  &::before {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    background: linear-gradient(135deg, #23bdb8 0, #43e794 100%);
    left: 0;
    border-radius: 4px;
  }
  &:hover,
  &:focus,
  &:active {
    color: $white-color;
    background: linear-gradient(90deg, #ee0979 0, #ff6a00 100%);
    box-shadow: unset !important;

    &::before {
      opacity: 1;
      visibility: visible;
      width: 100%;
    }
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: $white-color;
    background: linear-gradient(90deg, #ee0979 0, #ff6a00 100%);
    box-shadow: unset !important;

    &::before {
      opacity: 1;
      visibility: visible;
      width: 100%;
    }
  }
  &.disabled,
  &:disabled {
    color: $white-color;
    background: linear-gradient(90deg, #ee0979 0, #ff6a00 100%);
    box-shadow: unset !important;

    &::before {
      opacity: 1;
      visibility: visible;
      width: 100%;
    }
  }
}

/*================================================
Agency Portfolio Main Banner CSS
=================================================*/
.agency-portfolio-main-banner {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
}
.portfolio-banner-content {
  text-align: center;
  max-width: 700px;
  margin: {
    left: auto;
    right: auto;
    top: -50px;
  }
  .sub-title {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: $paragraph-color;
    margin-bottom: 10px;
  }
  h1 {
    margin-bottom: 15px;
    line-height: 1.3;
    font: {
      size: 40px;
      weight: 500;
    }
  }
  p {
    font-size: 15px;
    max-width: 490px;
    margin: {
      left: auto;
      right: auto;
    }
  }
  .btn {
    margin-top: 5px;
  }
}
.agency-portfolio-home-slides {
  .swiper-button-next,
  .swiper-button-prev {
    width: 50px;
    height: 50px;

    &:after {
      background-color: #e1e1e1;
      color: $black-color;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      transition: $transition;
      font-size: 25px;
    }
  }
}

/*================================================
Our Latest Projects CSS
=================================================*/
.our-latest-projects {
  position: relative;
}
.single-latest-projects {
  margin-bottom: 30px;
  position: relative;

  img {
    width: 100%;
  }

  .link-btn {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .content {
    position: absolute;
    left: -5%;
    top: 60%;
    transform: translateY(-60%);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    background-color: $white-color;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    padding: 25px;
    margin-top: 20px;

    span {
      display: block;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 8px;
    }
    h3 {
      margin-bottom: 0;
      font: {
        weight: 600;
        size: 20px;
      }
    }
  }
  &:hover {
    .content {
      opacity: 1;
      visibility: visible;
      margin-top: 0;
    }
  }
}

.shorting-menu {
  text-align: right;

  button {
    background-color: transparent;
    cursor: pointer;
    color: #848484;
    transition: $transition;
    margin-right: 15px;
    padding: 0;
    outline: 0;
    border: none;
    font: {
      size: $font-size;
      weight: 600;
    }
    &:hover,
    &.active {
      color: $black-color;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

/*================================================
Agency Portfolio About CSS
=================================================*/
.agency-portfolio-about-area {
  background-color: #f4f4f4;
}
.single-text-box {
  margin-bottom: 30px;
  h3 {
    margin: {
      bottom: 12px;
      top: 25px;
    }
    font: {
      weight: 600;
      size: 22px;
    }
  }
  .learn-more-btn {
    color: #365267;
    font: {
      size: $font-size;
      weight: 600;
    }
    svg {
      width: 18px;
      position: relative;
      top: -1px;
      margin-right: 3px;
    }
    &:hover {
      color: $light-green-color;
    }
  }
}

/*================================================
Agency Portfolio Partner CSS
=================================================*/
.agency-portfolio-partner-area {
  background-color: $black-color;
}
.single-agency-portfolio-partner {
  text-align: center;

  a {
    display: block;

    img {
      width: auto !important;
      display: inline-block !important;
    }
  }
}

/*================================================
Creative Area CSS
=================================================*/
.creative-area {
  height: 650px;
  background: {
    image: url(/default_images/creative-bg.jpg);
    position: center center;
    repeat: no-repeat;
    attachment: fixed;
    size: cover;
  }
  .container {
    position: relative;
  }
  .d-table-cell {
    vertical-align: bottom;
  }
}
.creative-inner-area {
  max-width: 600px;
  margin-left: auto;

  .single-counter {
    background-color: $black-color;
    padding: 60px;

    h3 {
      color: $white-color;
      margin-bottom: 5px;
      font: {
        size: 32px;
        weight: 700;
      }
      .odometer-formatting-mark {
        display: none;
      }
    }
    p {
      margin-bottom: 0;
      line-height: initial;
      color: $white-color;
      font: {
        size: 15px;
        weight: 600;
      }
    }
  }
  .row {
    margin: {
      left: 0;
      right: 0;
    }
  }
  .col-lg-6 {
    padding: {
      left: 0;
      right: 0;
    }
    &:nth-child(1) {
      .single-counter {
        background-color: $light-green-color;
      }
    }
    &:nth-child(2) {
      .single-counter {
        background-color: $black-color;
      }
    }
  }
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
  padding: {
    top: 80px;
  }
  position: relative;
  z-index: 1;

  .map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: -1;
    bottom: 0;
    opacity: 0.7;
  }
}
.single-footer-widget {
  .logo {
    margin-bottom: 20px;

    a {
      display: block;
    }
  }
  h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &.list {
      li {
        margin-bottom: 10px;

        a {
          color: $paragraph-color;

          &:hover {
            color: $light-green-color;
            padding-left: 7px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.footer-contact-info {
      margin-bottom: 20px;

      li {
        position: relative;
        color: $paragraph-color;
        margin-bottom: 8px;
        padding-left: 25px;

        svg {
          position: absolute;
          left: 0;
          width: 15px;
          height: auto;
          top: 2px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $paragraph-color;

          &:hover {
            color: $light-green-color;
          }
        }
      }
    }
    &.social-links {
      li {
        display: inline-block;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }

        a {
          width: 32px;
          text-align: center;
          line-height: 29px;
          height: 32px;
          border: 1px solid $light-green-color;
          border-radius: 50%;
          color: $light-green-color;

          svg {
            width: 15px;
          }
          &.facebook {
            border-color: #4267b2;
            color: #4267b2;

            &:hover {
              background: #4267b2;
              color: $white-color;
            }
          }
          &.twitter {
            border-color: #38a1f3;
            color: #38a1f3;

            &:hover {
              background: #38a1f3;
              color: $white-color;
            }
          }
          &.linkedin {
            border-color: #0077b5;
            color: #0077b5;

            &:hover {
              background: #0077b5;
              color: $white-color;
            }
          }
          &.instagram {
            border-color: #231f20;
            color: #231f20;

            &:hover {
              background: #231f20;
              color: $white-color;
            }
          }
          &:hover {
            background: $light-green-color;
            color: $white-color;
          }
        }
      }
    }
  }
}
.copyright-area {
  margin-top: 80px;
  border-top: 1px solid #d8ebfd;
  text-align: center;
  padding: {
    top: 25px;
    bottom: 25px;
  }
}

/*=============================
Go Top CSS
===============================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  right: 0;
  color: $white-color;
  background-color: $purple-color;
  width: 45px;
  text-align: center;
  height: 45px;
  border-radius: 10px 0 0 10px;
  line-height: 46px;
  transition: $transition;
  z-index: 999;

  &:hover {
    color: $white-color;
    background: $light-green-color;
  }
}
.main-text-area {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
  }
}

/* Auth Form */
.auth-form {
  text-align: center;
  max-width: 500px;
  width: 100%;
  box-shadow: 0px 0px 10px #eee;
  margin: auto;
  padding: 40px 30px;
  border-radius: 10px;
  border: 1px solid #edf2f9;

  .auth-head {
    margin-bottom: 15px;
    img {
      margin-bottom: 10px;
    }
  }
  form {
    .form-label {
      text-align: left;
      display: block;
    }
    .btn {
      display: block;
      width: 100%;
      padding: 12px 15px;
      margin-top: 20px;
    }
  }
  .foot {
    margin-top: 20px;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        a {
          i,
          svg {
            border: 1px solid #edf2f9;
            width: 35px;
            height: 35px;
            line-height: 35px;
            padding: 5px;
            margin: 0 10px;
          }
        }
      }
    }
  }
}

.lang-didebar {
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 999999;
  a {
    background-color: #000;
    color: #fff;
    display: block;
    padding: 5px 9px;
    &:last-child {
      background-color: #483e3e;
    }
  }
}
