body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cookie-consent-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f2;
  padding: 15px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.cookie-consent-popup p {
  margin: 0;
}

.cookie-consent-popup button {
  margin: 10px 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cookie-consent-popup button:hover {
  background-color: #0056b3;
}
